import React from 'react';
import PropTypes from 'prop-types';
import './animate.css';
import Vimeo from '@u-wave/react-vimeo';
import app from 'new-ui/app';

const Animate = ({ onDone, showForm, GA }) => {
  const handleVideoEnd = () => {
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.endVideo, { name: 'Upload file video finished' });
    onDone(true);
  };

  return (
    <div className="animate-wrapper">
      <div className="ngs-title">{window.t('upload_page.analyzing_your_files')}</div>
      <div className="animate-video">
        {!showForm ? (
          <Vimeo
            video="943598186"
            autoplay
            onEnd={handleVideoEnd}
            onPlay={()=>app.sendGoogleAnalyticsEvent(GA.category, GA.events.startVideo, { name: 'Upload file video started' })}
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          />
        ) : null}
      </div>

    </div>
  );
};

Animate.propTypes = {
  onDone: PropTypes.func,
  showForm: PropTypes.bool,
  GA: PropTypes.object,
};

export default Animate;
