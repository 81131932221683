import React from 'react';
import PropTypes from 'prop-types';
import * as ReactRouterDom from 'react-router-dom';
import * as queryString from 'query-string';
import { connect } from 'react-redux';

import { ROUTES, PAGES } from 'new-ui/constants';

import app from 'new-ui/app';

const PublicRoute = (p) => {
  const { tj_access_token } = queryString.parse(window.location.search);
  const {
    loggedIn, preventRedirect = false, component: Component, ...rest
  } = p;

  if (!preventRedirect && (loggedIn || app.user) && !tj_access_token) {
    const redirect = window.location.href.split('redirect=')[1];
    const url = redirect || ROUTES[PAGES.RESULTS];
    window.location = url;
    return null;
  }
  return <ReactRouterDom.Route {...rest} render={(props) => <Component {...props} />} />;
};

const mapStateToProps = (state) => ({
  loggedIn: !!(state.authentication && state.authentication.loggedIn && state.authentication.user),
});

PublicRoute.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default ReactRouterDom.withRouter(connect(mapStateToProps)(PublicRoute));
