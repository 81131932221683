/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import { hcpApi } from 'new-ui/Search/api';
import ProfileButton from 'new-ui/Components/ProfileButton';
import './Finance.css';

import Button from 'new-ui/Components/Button';
import {
  PAGES, POPUP_TYPES, ROUTES, THEMES, URI,
} from 'new-ui/constants';

import { ReactComponent as SearchIcon } from 'new-ui/assets/icons/search-zoom-in.svg';
import { ReactComponent as SearchIconBlack } from 'new-ui/assets/icons/search-zoom-in-black.svg';
import { ReactComponent as PatientsIcon } from 'new-ui/assets/icons/patients.svg';
import { ReactComponent as UserIcon } from 'new-ui/assets/icons/user2.svg';
import { ReactComponent as LoginIcon } from 'new-ui/assets/icons/login-button.svg';
import { ReactComponent as UserTickIcon } from 'new-ui/assets/icons/user_tick.svg';
import { addUserPopup } from 'new-ui/functions';
import { addCaregiver, addPatient } from 'new-ui/Search';
import { USER_TYPES } from 'new-ui/Components/QuestionRenderer/renderers/IntakeAboutYou';
import LinkButton from 'new-ui/Components/LinkButton';
import Preloader from '../../Components/Preloader';
import { getFinanceParams } from './functions';
import WithOnboarding from './WithOnboarding';
import WithoutOnboarding from './WithoutOnboarding';
import {
  getFinanceCategorties, getFinancePrograms, getFinanceMatch, getFinanceProfile, setFinanceUser,
} from './api';
import {
  useRouteChecker,
  arrangeCategories,
  saveCategoriesToLocalStorage,
  clearCategoriesFromLocalStorage,
  getCategoriesFromLocalStorage,
} from './utils';

const GA = {
  category: 'Finance',
  events: {
    unselectCategory: 'Unselect category',
    selectCategory: 'Select category',
    clickEligibility: 'Check eligibility clicked',
    videoStarted: 'Video started',
    checkEligibility: 'Check eligibility in category clicked',
    seeAllCategory: 'See all in category clicked',
    seeDetails: 'See details clicked',
    presentedResults: 'When results are presented',
    missingInfo: 'missing info clicked',
  },
};

export const createUserFinance = ({
  redirectTo,
  title,
  subtitle,
} = {})=>{
  addUserPopup({
    onSuccess: async (user)=>{
      console.log('finance page:: create user success', user);
      if (redirectTo) window.location = redirectTo;
    },
    title,
    subtitle,
  });
};

const FinancePage = ()=>{
  const { isDashboardFinance, isPublicFinance } = useRouteChecker();
  const [isPublicPatient, setIsPublicPatient] = useState(null);
  const [programs, setPrograms] = useState(null);
  const [matchedPrograms, setMatchedPrograms] = useState(null);
  const [filteredPrograms, setFilteredPrograms] = useState(null);
  const [categories, setCategories] = useState(null);
  const [metaData, setMetaData] = useState({});
  const [financeProfile, setFinanceProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => () => {
    app.setCustomHeader();
  }, []);

  const save = async ()=>{
    const patient = {
      condition_profile: {},
      info: {},
      personal: {},
    };
    patient.info.user_type = USER_TYPES.PATIENT;
    addCaregiver({ patient });
  };

  const doNew = ()=>{
    window.location = URI[PAGES.SEARCH];
  };

  const gotoLogin = ()=>{
    app.history.push(`${ROUTES[PAGES.LOGIN]}?redirect=${URI[PAGES.FINANCE]}`);
  };

  useEffect(()=>{
    setIsLoading(true);
    const fetch = async ()=>{
      await app.getSession();

      if (!app.user && app.isCurrentPage(PAGES.FINANCE)) setIsPublicPatient(true);

      if (isPublicFinance) {
        app.setCustomHeader(
          <>
            <div className="header-buttons">
              {
                app.user
                  ? app.hcpUser ? (
                    <Button theme={THEMES.BLACK} title={t('general.new')} action={doNew} customIcon={<SearchIcon />} />
                  ) : (
                    <Button theme={THEMES.BLACK} title={t('search.save')} action={addPatient} customIcon={<UserTickIcon />} />
                  )
                  : <Button theme={THEMES.BLACK} title={t('search.save')} action={save} customIcon={<UserTickIcon />} />
              }
              {
                app.user
                  ? (
                    <Button
                      theme={THEMES.GREY}
                      title={t('general.patients')}
                      action={()=>{
                        window.location = URI[PAGES.PATIENTS];
                      }}
                      customIcon={<PatientsIcon />}
                    />
                  )
                  : <Button theme={THEMES.GREY} title={t('general.new')} action={doNew} customIcon={<SearchIconBlack />} />
              }

              {
                // <Button theme={THEMES.GREY} title={t('general.login')} action={gotoLogin} customIcon={<UserTickIcon />} />
                !app.user ? (
                  <>
                    <Button className="mobile-only" theme={THEMES.GREY} title={t('general.login')} action={gotoLogin} customIcon={<LoginIcon />} />
                    <Button className="desktop-only" theme={THEMES.GREY} title={t('general.login')} action={gotoLogin} customIcon={<UserIcon />} />
                  </>
                ) : (
                  <ProfileButton />
                )
              }
              {/* <Button theme={THEMES.GREY} title={t('search.goto_dashboard')} action={gotoDashboard} customIcon={<CheckIcon />} /> */}
            </div>
          </>,
        );
      } else if (!app.user) {
        app.setCustomHeader(
          <>
            <div className="header-buttons">
              <Button className="mobile-only" theme={THEMES.GREY} title={t('general.login')} action={gotoLogin} customIcon={<LoginIcon />} />
              <Button className="desktop-only" theme={THEMES.GREY} title={t('general.login')} action={gotoLogin} customIcon={<UserIcon />} />
              <LinkButton
                title={t('general.register')}
                action={()=>{
                  window.location = '/?newprofile=true';
                }}
              />
            </div>
          </>,
        );
      }

      const aidTypes = [];

      let financeProfile = null;

      if (!isPublicFinance) {
        try {
          financeProfile = await getFinanceProfile();
        } catch (err) {

        }
      }

      if (financeProfile) {
        if (financeProfile.aidTypes) {
          const existingAidTypes = financeProfile.aidTypes.split(',');
          aidTypes.push(...existingAidTypes);
        }
        const matchedPrograms = await getFinanceMatch(getFinanceParams(app.user, financeProfile, aidTypes));
        setMatchedPrograms(matchedPrograms);
        clearCategoriesFromLocalStorage();
      }

      let programs = [];
      let categories = [];
      if (!isPublicFinance && app.user) {
        programs = await getFinancePrograms();
        categories = arrangeCategories(await getFinanceCategorties(), programs, aidTypes);
      } else {
        programs = [];

        if (localStorage.getItem('finance_params')) {
          // localStorage.setItem('finance_params', JSON.stringify(financeParams));
          // localStorage.setItem('finance_user', JSON.stringify(user));
          const financeParams = JSON.parse(localStorage.getItem('finance_params'));
          try {
            programs = await hcpApi.financeMatch(financeParams);
            setFilteredPrograms(programs);
            clearCategoriesFromLocalStorage();
          } catch (e) {
            console.log('cannot get programs for params', financeParams);
          }
        }

        categories = arrangeCategories([
          'Co-Pay',
          'Mental Health Support',
          'Pet Care Support',
          'Meal & Food Support',
          'Support Group',
          'Financial Support',
          'Lodging',
          'Medical Expenses',
          'Transportation',
          'Travel Expenses',
          'Caregiver Support',
          'Legal Assistance',
          'Medical Support',
          'Child Care Support',
        ], [], []);
      }

      if (!financeProfile && !getCategoriesFromLocalStorage()) {
        saveCategoriesToLocalStorage(categories);
      }
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        program_count: programs?.length,
      }));

      setPrograms(programs);
      setCategories(categories);
      setFinanceProfile(financeProfile);
      setIsLoading(false);

      if (app.getParams().get('create')) {
        createUserFinance({
          redirectTo: URI[PAGES.FINANCE],
          subtitle: t('support_programs.create_user_subtitle_register'),
        });
      }
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  const onCategorySelect = useCallback(async (categories) => {
    const aidTypes = categories.map((category) => category.label);
    if (financeProfile) {
      const matchedPrograms = await getFinanceMatch(getFinanceParams(app.user, financeProfile, aidTypes));
      const params = getFinanceParams(app.user, financeProfile, aidTypes);
      delete params.userWixId;
      delete params.email;
      await setFinanceUser({ ...params });
      setMatchedPrograms(matchedPrograms);
    }
  }, [financeProfile]);

  const onCategorySelectWithoutAuth = useCallback((categories) => {
    saveCategoriesToLocalStorage(categories);
  }, []);

  const onSeeAllClick = async (option) => {
    const filteredPrograms = programs.filter((program) => program.category === option.label);
    setFilteredPrograms(filteredPrograms);
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      selected_category: option,
    }));
  };

  const onBackClick = () => {
    setFilteredPrograms(null);
  };

  return (
    <div className="finance-page">
      <div className="finance-page-header">
        {isLoading ? <Preloader isVisible /> : (
          financeProfile
            ? (
              <WithOnboarding
                categories={categories}
                matchedPrograms={matchedPrograms}
                onCategorySelect={onCategorySelect}
                meta={metaData}
                GA={GA}
                isPublicFinance={isPublicFinance}
              />
            )
            : (
              <WithoutOnboarding
                categories={categories}
                filteredPrograms={filteredPrograms}
                meta={metaData}
                onSeeAllClick={onSeeAllClick}
                onBackClick={onBackClick}
                onCategorySelect={onCategorySelectWithoutAuth}
                GA={GA}
                isPublicFinance={isPublicFinance}
              />
            )
        )}
      </div>
    </div>
  );
};

export default FinancePage;
