import configs from '../../configs';

// TODO temporary. Need to refactor.
export const allCancerTypes = {
  MELANOMA: 'melanoma',
  MELANOMA_2: 'melanoma_2',
  CRC: 'crc',
  CLL: 'cll',
  BC: 'breast_cancer',
  MDS: 'mds',
  MPN: 'mpn',
  BLADDER: 'bladder',
  BLADDER_2: 'bladder_2',
  UTERINE: 'uterine',
  BRAIN: 'brain',
  MM: 'mm',
  LUNG: 'lung',
  BREAST_2: 'breast_2',
  NHL: 'nhl',
  CRC_2: 'crc_2',
  PROSTATE: 'prostate',
  AML: 'aml',
  HNSCC: 'hnscc',
  GI_TRACT: 'gi_tract',
  OVARIAN: 'ovarian',
  HCC: 'hcc',
  RCC: 'rcc_kidney',
  MESO: 'mesothelioma',
};

export const UNKNOWN_CANCER = 'unknown_cancer';
export const ALL_CANCER = 'all_cancer';

export const CancerFieldsByIndication = {
  [allCancerTypes.MELANOMA_2]: {
    diseaseType: 'melanoma_subtype',
    patientStage: 'current_stage',
  },
  [allCancerTypes.MDS]: {
    diseaseType: 'type_of_mds',
    riskGroup: 'risk_group',
  },
  [allCancerTypes.MPN]: {
    diseaseType: 'type_of_mpn',
    riskGroup: 'risk_group',
  },
  [allCancerTypes.UTERINE]: {
    diseaseType: 'type_of_uterine_cancer',
    patientStage: 'current_stage',
    type_of_carcinoma: 'type_of_carcinoma',
    type_of_sarcoma: 'type_of_sarcoma',
  },
  [allCancerTypes.BRAIN]: {
    diseaseType: 'brain_cancer_type',
  },
  [allCancerTypes.MM]: {
    diseaseType: 'type_of_mm',
  },
  [allCancerTypes.LUNG]: {
    diseaseType: 'type_of_lung_cancer',
    patientStage: 'current_stage',
  },
  [allCancerTypes.BREAST_2]: {
    diseaseType: 'type_of_bc',
    patientStage: 'patient_stage',
    molecular_subtype: 'molecular_subtype',
  },
  [allCancerTypes.NHL]: {
    diseaseType: 'type_of_lymphoma',
    patientStage: 'current_stage',
    type_of_tcell_lymphoma: 'type_of_tcell_lymphoma',
    type_of_bcell_lymphoma: 'type_of_bcell_lymphoma',
    type_of_aids_related_lymphoma: 'type_of_aids_related_lymphoma',
  },
  [allCancerTypes.CRC_2]: {
    diseaseType: 'type_of_crc',
    patientStage: 'current_stage',
    type_of_familial_cancer: 'type_of_familial_cancer',
  },
  [allCancerTypes.PROSTATE]: {
    diseaseType: 'type_of_pc',
    patientStage: 'current_stage',
    patientSubStage: 'sub_stage',
  },
  [allCancerTypes.AML]: {
    diseaseType: 'type_of_aml',
    riskGroup: 'risk_group',
    riskGroupApl: 'risk_group_apl',
  },
  [allCancerTypes.HNSCC]: {
    diseaseType: 'type_of_hnscc',
    patientStage: 'current_stage',
  },
  [allCancerTypes.BLADDER_2]: {
    diseaseType: 'type_of_bladder_cancer',
    patientStageNmibc: 'patient_stage_nmibc',
    patientStageMibc: 'patient_stage_mibc',
    patientStageMet: 'patient_stage_met',
  },
  [allCancerTypes.CLL]: {
    diseaseType: 'type_of_disease',
    patientStage: 'disease_stage',
    riskGroupIpi: 'risk_group_ipi',
  },
  [allCancerTypes.GI_TRACT]: {
    diseaseType: 'type_of_gi',
    patientStage: 'current_stage',
  },
  [allCancerTypes.OVARIAN]: {
    diseaseType: 'type_of_ovarian_cancer',
    patientStage: 'current_stage',
    type_of_ovarian_cancer: 'type_of_ovarian_cancer',
  },
  [allCancerTypes.HCC]: {
    diseaseType: 'type_of_liver',
    patientStage: 'current_stage',
  },
};

export const cancerTypes = (() => {
  const supportedCancers = {
    AML: 'aml',
    BLADDER_2: 'bladder_2',
    BRAIN: 'brain',
    BREAST_2: 'breast_2',
    CLL: 'cll',
    CRC_2: 'crc_2',
    GI_TRACT: 'gi_tract',
    HCC: 'hcc',
    HNSCC: 'hnscc',
    LUNG: 'lung',
    MDS: 'mds',
    MELANOMA_2: 'melanoma_2',
    MM: 'mm',
    MPN: 'mpn',
    NHL: 'nhl',
    OVARIAN: 'ovarian',
    PROSTATE: 'prostate',
    UTERINE: 'uterine',
  };

  const excludeOnProd = [''];

  return Object.entries(supportedCancers).reduce((acc, cur) => {
    const [key, cancerType] = cur;

    if (configs.isProduction && excludeOnProd.includes(cancerType)) {
      return acc;
    }

    acc[key] = cancerType;
    return acc;
  }, {});
})();

export const diseaseNameTranslationKeyByCancerTypes = Object.values(cancerTypes).reduce((translateKeys, cancerType) => {
  translateKeys[cancerType] = `disease_name.cancer_types.${cancerType}`;
  return translateKeys;
}, {});

export const supportedCancerTypes = Object.keys(diseaseNameTranslationKeyByCancerTypes).map((key) => ({
  label: diseaseNameTranslationKeyByCancerTypes[key],
  value: key,
}));

export default supportedCancerTypes;
