import { createFileProcessingJob, getUserProfile, uploadFiles } from 'components/MedicalFiles/api';
import { EXTRACTION_STRATEGY, JOB_STATUS } from 'components/MedicalFiles/constants';
import { poll } from 'components/MedicalFiles/utils';
import app from 'new-ui/app';

const setResponseData = async (responseData, keep_qstack)=>{
  const setUserData = (data) => ({ type: 'SET_USER_DATA', payload: data });
  app.store.dispatch(setUserData(responseData));
  if (keep_qstack && responseData?.condition_profile?.qstack && app?.user?.condition_profile?.qstack) {
    responseData.condition_profile.qstack = app.user.condition_profile.qstack;
  }
  app.user = responseData;
};

export const uploadFile = async (
  files = [],
  getRecaptchaTokenAsync,
  onupload,
  onerror,
  extractionStrategy = EXTRACTION_STRATEGY.FULL_PROFILE,
  indication = undefined,
  prevent_set_user,
  keep_qstack,
) => {
  try {
    let questions;
    const fileKeys = await uploadFiles(files, await getRecaptchaTokenAsync());
    const id = await createFileProcessingJob(fileKeys, extractionStrategy, await getRecaptchaTokenAsync(), indication);
    if (indication) questions = await app.getQuestions(indication, true);
    const profile = await poll(
      () => getUserProfile(id),
      (job) => {
        if (job.status === JOB_STATUS.IN_PROGRESS) {
          return true;
        }
        if (job.status === JOB_STATUS.UPDATED) {
          if (job.indication) {
            const responseData = {
              personal: {
                ...app.user?.personal,
                ...job?.profile.personal,
                condition: job?.indication,
              },
              info: {
                ...app.user?.info,
                ...job?.profile?.info,
                condition: job?.indication,
              },
              condition_profile: {
                ...app.user?.condition_profile,
                ...job?.profile?.condition_profile,
              },
            };
            if (!prevent_set_user) setResponseData(responseData, keep_qstack, questions);
          }
          return true;
        }
        if (job.status === JOB_STATUS.COMPLETED || job.status === JOB_STATUS.COMPLETED_WITH_ALERT) {
          const responseData = {
            personal: {
              ...app.user?.personal,
              ...job?.profile?.personal,
              condition: job?.indication,
            },
            info: {
              ...job?.profile.info,
              ...app.user?.info,
              condition: job?.indication,
            },
            condition_profile: {
              ...app.user?.condition_profile,
              ...job?.profile?.condition_profile,
            },
          };
          if (!prevent_set_user) setResponseData(responseData, keep_qstack, questions);
          return false;
        }
      },
      5000,
    );

    if (onupload) {
      return onupload(profile);
    }
    return profile;
  } catch (e) {
    if (onerror) {
      onerror(e);
    }
  }
};
