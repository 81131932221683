import React from 'react';
import './style.css';
import { isMobile } from 'new-ui/functions';
import FinanceCategorySelect from '../FinanceCategorySelect';
import SupportPrograms from '../SupportPrograms';

const WithOnboarding = ({
  categories, matchedPrograms, onCategorySelect, meta, GA, isPublicFinance,
}) => {
  const maxItems = isMobile() ? 3 : null;
  return (
    <div>
      {categories?.length && (
        <FinanceCategorySelect
          options={categories}
          onSelectionChange={onCategorySelect}
          meta={meta}
          maxItems={maxItems}
        />
      )}
      {!isPublicFinance && (
        <>
          <SupportPrograms programs={matchedPrograms} GA={GA} />
        </>
      )}
    </div>
  );
};

export default WithOnboarding;
