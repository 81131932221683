import React from 'react';
import './style.css';
import app from 'new-ui/app';
import { PAGES, URI } from 'new-ui/constants';
import { isMobile } from 'new-ui/functions';
import { t } from 'utils/modifiedTranslation';
import FinanceCategorySelect from '../FinanceCategorySelect';
import FinanceCategoryCards from '../FinanceCategoryCards';
import FinanceReview from '../FinanceReview';
import FilteredPrograms from '../FilteredPrograms';
import { getCategoriesWithoutOnboarding } from '../utils';
import SupportPrograms from '../SupportPrograms';
import { createUserFinance } from '..';

const WithoutOnboarding = ({
  categories, meta, filteredPrograms, onSeeAllClick, onBackClick, onCategorySelect, GA, isPublicFinance,
}) => {
  const onCheckEligibilityClick = (option) => {
    if (app.isCurrentPage(PAGES.FINANCE) && !app.user) {
      createUserFinance({
        redirectTo: URI[PAGES.FINANCE_INTAKE],
        subtitle: t('support_programs.create_user_subtitle_eligibility'),
      });
      return;
    }
    app.history.push(URI[PAGES.FINANCE_INTAKE]);
  };

  const review = {
    comment: `We stayed at the 'Hope' Lodge near the Memorial Institute in NYC to be able to participate in 
    an advanced trial. In total, we stayed there for 21 nights, which might have cost us $9000.`,
    author: 'Tim Kavanagh, a 5-time colorectal cancer survivor',
    YOUTUBE_VIDEO_ID: 'gzhJO0NCBwA',
  };

  const showAll = !isMobile();
  const maxItems = isMobile() ? 3 : null;
  return (
    <div>
      {categories?.length && (
        <FinanceCategorySelect
          options={getCategoriesWithoutOnboarding(categories)}
          onSelectionChange={onCategorySelect}
          meta={meta}
          showAll={showAll}
          maxItems={maxItems}
          GA={GA}
        />
      )}
      {!isPublicFinance && (
        <>
          <FinanceReview review={review} GA={GA} />
          {categories?.length && (
            <FinanceCategoryCards
              GA={GA}
              options={categories}
              onSeeAllClick={onSeeAllClick}
              onCheckEligibilityClick={onCheckEligibilityClick}
            />
          )}
          {filteredPrograms && (
            <FilteredPrograms
              programs={filteredPrograms}
              categories={[meta.selected_category]}
              onBackClick={onBackClick}
              GA={GA}
            />
          )}
        </>
      )}
      {isPublicFinance && filteredPrograms && <SupportPrograms programs={filteredPrograms} GA={GA} />}
    </div>
  );
};

export default WithoutOnboarding;
