/* eslint-disable quotes */
/* eslint-disable arrow-parens */
/* eslint-disable operator-linebreak */
// eslist-disable

import React, { useEffect, useState } from 'react';
import app from 'new-ui/app';
import GenericChat from '../../Components/GenericChat';
import { updateChatScoreUsingThumb } from '../Chat/functions';
import { handleSendMessage } from './functions';

const ChatUploadExplainPopup = () => {
  const [init, setInit] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);

  // eslint-disable-next-line max-len
  const msg = ` Welcome! Let's get you started on the path to finding the best treatment options. Ask me anything about uploading your files to Leal.health, and I'll be happy to help.`;
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setChatHistory(
      app.chatHistory || [
        {
          role: 'Jane (patient navigator)',
          content: `${msg}`,
          daynamicContent: null,
        },
      ],
    );
    setInit(true);
  }, []);

  // eslist-disable
  useEffect(() => {
    const $chatPopup = document.querySelector('.chat-popup');
    if ($chatPopup) {
      $chatPopup.scrollTop = $chatPopup.scrollHeight;
    }
    app.chatHistory = chatHistory;
  }, [chatHistory]);

  const handleSendMessageClick = async prompt => {
    await handleSendMessage(prompt, chatHistory, setChatHistory, setLoading);
  };

  return init ? (
    <GenericChat
      handleSendMessageClick={handleSendMessageClick}
      updateChatScoreUsingThumb={updateChatScoreUsingThumb}
      chatHistory={chatHistory}
    />
  ) : null;
};

export default ChatUploadExplainPopup;
