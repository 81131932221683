import React, { useState } from 'react';
import './ResultListHeader.css';

import app from 'new-ui/app';

import arrowDown from 'new-ui/assets/icons/arrow-circle-black.svg';
import ActionsIcon from 'new-ui/assets/icons/more-circle.svg';
// import knobsIcon from 'new-ui/assets/icons/filter.svg';
import MapIcon from 'new-ui/assets/icons/trials_map.svg';
import ListIcon from 'new-ui/assets/icons/trials_list.svg';
import EmailIcon from 'new-ui/assets/icons/email.svg';
import DownloadIcon from 'new-ui/assets/icons/download-pdf.svg';

import {
  INTERCOM_EVENTS, PAGES, POPUP_TYPES, ROUTES,
} from 'new-ui/constants';
// import { getSearchString } from 'new-ui/functions';
import { useTranslation } from 'utils/modifiedTranslation';

import PropTypes from 'prop-types';
import pdfDownloadEvents, {
  GA_CATEGORY_NAME as GS_PDF_CATEGORY,
} from 'new-ui/Components/GoogleAnalytics/pdfDownloadEvents';
import sendGoogleAnalyticsEvent from 'new-ui/Components/GoogleAnalytics';
import ButtonItem from 'new-ui/Components/ButtonItem';
import DropdownButton from 'new-ui/Components/DropdownButton';
import { download } from '../ResultActions';
import { RESULT_LIST_TABS } from '.';
import InfoNote from './InfoNote/InfoNote';

const ResultListHeader = (props)=>{
  const {
    treatments = [],
    trials = [],
    displayMap,
    setDisplayMap,
    // setTab,
    currentTab,
    customActions,
    mobileHeaderPrepend,
    isSearchPage,
  } = props;

  const [showMobileTabs, setShowMobileTabs] = useState(false);
  const [showMobileActions, setShowMobileActions] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [hasFilters] = useState(false);

  const { t } = useTranslation();

  // const setFilters = (filters) => {
  //   app.setPopup(false);
  //   // console.log('setFilters', filters);
  //   window.d = filters;
  //   app.history.push(ROUTES[PAGES.RESULTS] + getSearchString(filters));
  // };

  // const openFilters = () => {
  //   app.setPopup(POPUP_TYPES.PERSONALIZE, {
  //     openLocationPopup: () => { app.setPopup(POPUP_TYPES.CHANGE_LOCATION); },
  //     onSave: setFilters,
  //   });
  // };

  const downloadPDF = () => {
    const { user, lang } = app;
    setFileLoading(true);
    download(user, trials, lang, () => {
      setFileLoading(false);
      const pdfEvent = {
        [RESULT_LIST_TABS.TRIALS]: pdfDownloadEvents.downloadPDFWithoutSOC,
        [RESULT_LIST_TABS.TREATMENTS]: pdfDownloadEvents.downloadPDFWithSOC,
      };
      const { eventName, getMetaData } = pdfEvent[currentTab];
      sendGoogleAnalyticsEvent(GS_PDF_CATEGORY, eventName, getMetaData());
    }, currentTab);
  };

  const getSortOptions = ()=>{
    const options = [{
      title: t('general.most_matched'),
      action: ()=>{
        app.history.push(ROUTES[PAGES.RESULTS]);
      },
    },
    {
      title: t('general.most_recent'),
      action: ()=>{
        app.history.push(`${ROUTES[PAGES.RESULTS]}?sort=recent`);
      },
    },
    ];
    if (app.getParams().get('sort') === 'recent') options[1].isSelected = true;
    else options[0].isSelected = true;
    return options;
  };

  const renderSort = ()=>{
    return (
      <DropdownButton
        className="result-list-header-sort"
        options={getSortOptions()}
      />
    );
  };

  const renderActions = () => {
    return (
      <div className={`results-header ${hasFilters && 'results-filters'}`}>
        {customActions ? customActions() : (
          <>
            {
              renderSort()
            }
            {/* <ButtonItem action={openFilters} icon={knobsIcon} title={t('general.filter')} /> */}
            {
              displayMap
                ? <ButtonItem icon={ListIcon} action={() => setDisplayMap(false)} title={t('match.list.show_list')} />
                : currentTab === RESULT_LIST_TABS.TRIALS ? <ButtonItem icon={MapIcon} action={() => setDisplayMap(true)} title={t('match.list.show_map')} /> : null
            }
            <ButtonItem
              icon={EmailIcon}
              action={() => {
                app.intercom.sendEvent(currentTab === RESULT_LIST_TABS.TRIALS ? INTERCOM_EVENTS.SHARE.TRIALS : INTERCOM_EVENTS.SHARE.TREATMENTS);
                app.setPopup(POPUP_TYPES.SHARE_WITH_DOCTOR, { type: currentTab });
              }}
              title={t('match.list.share')}
            />
            <ButtonItem
              icon={DownloadIcon}
              action={downloadPDF}
              title={t('match.list.download')}
              className="download"
              loading={fileLoading}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="result-list-header">
      <div className="result-list-header-main">
        {/* display selector | filter tags */}
      </div>
      <div className="result-list-header-actions">
        <div className="result-list-mobile-header-actions-menu" onClick={() => { setShowMobileActions(!showMobileActions); }}>
          <img alt="" src={ActionsIcon} />
          <div className={`result-list-mobile-actions ${showMobileActions ? 'mobile-actions-show' : ''}`}>
            {renderActions()}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="result-list-mobile-header-wrapper mobile-only">
        <div className={`result-list-mobile-tabs ${showMobileTabs ? 'mobile-tabs-show' : ''}`}>
          <div className="result-list-mobile-tabs-content">
            <div className="result-list-mobile-tabs-content-arrow" onClick={() => { setShowMobileTabs(false); }}>
              <img alt="" src={arrowDown} />
            </div>
          </div>
        </div>
        <div className="result-list-mobile-header">
          { mobileHeaderPrepend ? mobileHeaderPrepend() : null }
          <div
            className="result-list-mobile-header-tabs"
            onClick={() => {
              setShowMobileTabs(!showMobileTabs);
            }}
          >
            {/* <div className="result-list-mobile-header-tabs-title">{t(RESULT_LIST_TITLES[currentTab])}</div> */}
            {/* <img className="rotate-180" alt="" src={arrowDown} /> */}
          </div>
          <div className="result-list-mobile-header-actions">
            {
              renderSort()
            }
            <div className="result-list-mobile-header-actions-menu" onClick={() => { setShowMobileActions(!showMobileActions); }}>
              <img alt="" src={ActionsIcon} />
              <div className={`result-list-mobile-actions ${showMobileActions ? 'mobile-actions-show' : ''}`}>
                {renderActions()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        displayMap ? (
          <div className="desktop-only">
            {renderActions()}
          </div>
        ) : (((treatments.length && app.isSOCSupported()) || trials.length) ? (
          <>
            <div className="desktop-only">
              {isSearchPage && currentTab === RESULT_LIST_TABS.TREATMENTS ? <InfoNote info="The matched treatment options are based on the information you provided. It is important to discuss them with your oncologist to determine the most suitable treatment plan for you" /> : null}
              {renderActions()}
            </div>
          </>
        ) : null)
      }
    </>
  );
};

ResultListHeader.propTypes = {
  treatments: PropTypes.any,
  trials: PropTypes.any,
  displayMap: PropTypes.any,
  setDisplayMap: PropTypes.any,
  setTab: PropTypes.any,
  currentTab: PropTypes.any,
  customActions: PropTypes.func,
  mobileHeaderPrepend: PropTypes.any,
  isSearchPage: PropTypes.bool,
};

export default ResultListHeader;
