import {
  SINGLE_OPTION_SELECT,
  MULTIPLE_OPTION_SELECT,
  MONTH_PICKER,
  TREATMENT_HISTORY,
  NUMBER_INPUT,
  PROCEDURES,
  TRIPLE_QUESTION_DROP_DOWN,
  MULTIPLE_OPTION_SEARCH_SELECT,
  BIOMARKERS,
  OPTIONS_FROM_SERVER,
} from '../../constants/questionTypes';
import dateType from '../../../../utils/constants/dateTypes';
import { Profile as ID } from './LungProfile.enum';
import numberType from '../../../../utils/constants/numberTypes';
import ConditionTypes from '../../constants/conditions';
import i from '../../constants/icons';
import {
  preferredLocation,
  travelLimit,
  userType,
  dateOfBirth,
  race,
  haveInsurance,
  insurance,
  diseaseStatus,
  pdL1ExpressionByQNumber,
  initialDiagnosisDate,
} from '../../constants/globalQuestions';
import {
  // takingStateOptions,
  yesAndNoOptions,
  treatmentCyclesOptions,
} from '../../constants/globalOptions';
import SECTIONS from '../../constants/questionSections';
import questionOptions from '../../constants/questionOptions';
import { QUESTION_VIEW_TYPES } from '../../constants/questionViewTypes';
import GLOBAL_ENUMS from '../../constants/globalEnums';

const { biomarkers: BIOMARKERS_QUESTION_ID } = GLOBAL_ENUMS;

const { OPTIONS_LIST_WITH_SHOW_MORE } = QUESTION_VIEW_TYPES;

const noneOption = questionOptions.NONE;
const {
  NOT_EQUAL_TO_OR_SKIPPED,
  IS_SKIPPED,
  DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
  CONTAIN_ONE_OF,
  AGE_LESS_THAN,
  EQUAL_TO,
  CONTAINS_ONE_OF_OR_SKIPPED,
} = ConditionTypes;

const Questions = [
  diseaseStatus,
  {
    q: 'Q6',
    id: ID.type_of_lung_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.lung.2_type_of_lung_cancer.title',

    info: {
      title: 'questionnaire.questions.lung.2_type_of_lung_cancer.info.title',
      content: 'questionnaire.questions.lung.2_type_of_lung_cancer.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.2_type_of_lung_cancer.options.nsclc',
        value: 'nsclc',
      },
      {
        label: 'questionnaire.questions.lung.2_type_of_lung_cancer.options.sclc_limited_stage',
        value: 'sclc_limited_stage',
      },
      {
        label: 'questionnaire.questions.lung.2_type_of_lung_cancer.options.sclc_extensive_stage',
        value: 'sclc_extensive_stage',
      },
      {
        label: 'questionnaire.questions.lung.2_type_of_lung_cancer.options.carcinoid',
        value: 'carcinoid',
      },
      {
        label: 'questionnaire.questions.lung.2_type_of_lung_cancer.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q6a',
    id: ID.type_of_nsclc,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.lung.3_type_of_nsclc.title',

    info: {
      title: 'questionnaire.questions.lung.3_type_of_nsclc.info.title',
      content: 'questionnaire.questions.lung.3_type_of_nsclc.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.3_type_of_nsclc.options.adenocarcinoma',
        value: 'adenocarcinoma',
      },
      {
        label: 'questionnaire.questions.lung.3_type_of_nsclc.options.scc',
        value: 'scc',
      },
      {
        label: 'questionnaire.questions.lung.3_type_of_nsclc.options.large_cell',
        value: 'large_cell',
      },
      {
        label: 'questionnaire.questions.lung.3_type_of_nsclc.options.adenosquamous',
        value: 'adenosquamous',
      },
      {
        label: 'questionnaire.questions.lung.3_type_of_nsclc.options.sarcomatoid',
        value: 'sarcomatoid',
      },

      {
        label: 'questionnaire.questions.lung.3_type_of_nsclc.options.large_cell_neuroendocrine',
        value: 'large_cell_neuroendocrine',
      },
      {
        label: 'questionnaire.questions.lung.3_type_of_nsclc.options.carcinoid_tumor',
        value: 'carcinoid_tumor',
      },
      {
        label: 'questionnaire.questions.lung.3_type_of_nsclc.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_lung_cancer,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['sclc_limited_stage', 'sclc_extensive_stage'],
      },
    ],
  },
  {
    q: 'Q8',
    id: ID.current_stage,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.lung.4_current_stage.title',
    subtitle: 'questionnaire.questions.lung.4_current_stage.subtitle',
    info: {
      title: 'questionnaire.questions.lung.4_current_stage.info.title',
      content: 'questionnaire.questions.lung.4_current_stage.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.4_current_stage.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.lung.4_current_stage.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.lung.4_current_stage.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.lung.4_current_stage.options.3.1',
        value: '3.1',
      },
      {
        label: 'questionnaire.questions.lung.4_current_stage.options.3.2',
        value: '3.2',
      },
      {
        label: 'questionnaire.questions.lung.4_current_stage.options.3.3',
        value: '3.3',
      },
      {
        label: 'questionnaire.questions.lung.4_current_stage.options.4.1',
        value: '4.1',
      },
      {
        label: 'questionnaire.questions.lung.4_current_stage.options.4.2',
        value: '4.2',
      },
    ],
    conditions: [
      {
        ifId: ID.type_of_lung_cancer,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['sclc_limited_stage', 'sclc_extensive_stage'],
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q11',
    id: ID.treatment_for_lung_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.lung.19_treatment_for_lung_cancer.title',

    info: {
      title: '',
      content: '',
    },
    options: yesAndNoOptions,
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q23',
    id: ID.disease_resectable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.lung.16_disease_resectable.title',

    info: {
      title: 'questionnaire.questions.lung.16_disease_resectable.info.title',
      content: 'questionnaire.questions.lung.16_disease_resectable.info.content',
    },
    options: [
      ...yesAndNoOptions,
      {
        label: 'questionnaire.questions.lung.16_disease_resectable.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'recurrence',
      },
      // {
      //   ifId: ID.current_stage,
      //   type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
      //   ifValue: ['4.1', '4.2'],
      // },
      {
        ifId: ID.current_stage,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['0', '1', '2', '3.1', '3.2', '4.1', '4.2'],
      },
    ],
  },
  {
    q: 'Q9',
    id: ID.triple_question_drop_down,
    type: TRIPLE_QUESTION_DROP_DOWN,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.lung.5_triple_question_drop_down.title',
    subtitle: 'questionnaire.questions.lung.5_triple_question_drop_down.subtitle',
    info: {
      title: 'questionnaire.questions.lung.5_triple_question_drop_down.info.title',
      content: 'questionnaire.questions.lung.5_triple_question_drop_down.info.content',
    },
    options: [
      {
        title: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_t.title',
        name: ID.tnm_t,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_t.options.1',
            value: '1',
          },
          {
            label: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_t.options.2',
            value: '2',
          },
          {
            label: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_t.options.3',
            value: '3',
          },
          {
            label: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_t.options.4_1',
            value: '4.1',
          },
          {
            label: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_t.options.4_2',
            value: '4.2',
          },
        ],
      },
      {
        title: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_n.title',
        name: ID.tnm_n,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_n.options.0',
            value: '0',
          },
          {
            label: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_n.options.1',
            value: '1',
          },
          {
            label: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_n.options.2',
            value: '2',
          },
          {
            label: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_n.options.3',
            value: '3',
          },
        ],
      },
      {
        title: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_m.title',
        name: ID.tnm_m,
        options: [
          {
            label: '',
            value: '',
          },
          {
            label: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_m.options.0',
            value: '0',
          },
          {
            label: 'questionnaire.questions.lung.5_triple_question_drop_down.options.tnm_m.options.1',
            value: '1',
          },
        ],
      },
    ],
    conditions: [
      {
        ifId: ID.current_stage,
        type: IS_SKIPPED,
      },
      {
        ifId: ID.type_of_lung_cancer,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['sclc_limited_stage', 'sclc_extensive_stage'],
      },
    ],
  },
  {
    q: 'Q10',
    id: ID.disease_location,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.lung.6_disease_location.title',
    subtitle: 'questionnaire.questions.lung.6_disease_location.subtitle',
    info: {
      title: 'questionnaire.questions.lung.6_disease_location.info.title',
      content: 'questionnaire.questions.lung.6_disease_location.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.6_disease_location.options.lungs',
        value: 'lungs',
      },
      {
        label: 'questionnaire.questions.lung.6_disease_location.options.sentinel_ln',
        value: 'sentinel_ln',
      },
      {
        label: 'questionnaire.questions.lung.6_disease_location.options.adrenal_glands',
        value: 'adrenal_glands',
      },
      {
        label: 'questionnaire.questions.lung.6_disease_location.options.bones',
        value: 'bones',
      },
      {
        label: 'questionnaire.questions.lung.6_disease_location.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.lung.6_disease_location.options.brain',
        value: 'brain',
      },
      {
        label: 'questionnaire.questions.lung.6_disease_location.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.lung.6_disease_location.options.lymph_nodes',
        value: 'lymph_nodes',
      },
      {
        label: 'questionnaire.questions.lung.6_disease_location.options.pleural_effusion',
        value: 'pleural_effusion',
      },
      {
        label: 'questionnaire.questions.lung.6_disease_location.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
  },
  {
    q: 'Q18',
    id: ID.metastasis_location_brain_leptomeningeal,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.lung.7_metastasis_location_brain_leptomeningeal.title',
    subtitle: 'questionnaire.questions.lung.7_metastasis_location_brain_leptomeningeal.subtitle',
    info: {
      title: 'questionnaire.questions.lung.7_metastasis_location_brain_leptomeningeal.info.title',
      content: 'questionnaire.questions.lung.7_metastasis_location_brain_leptomeningeal.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  {
    q: 'Q19',
    id: ID.brain_metastasis_active,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.lung.8_brain_metastasis_active.title',
    subtitle: 'questionnaire.questions.lung.8_brain_metastasis_active.subtitle',
    info: {
      title: 'questionnaire.questions.lung.8_brain_metastasis_active.info.title',
      content: 'questionnaire.questions.lung.8_brain_metastasis_active.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.disease_location,
        type: CONTAIN_ONE_OF,
        ifValue: ['brain'],
      },
    ],
  },
  // {
  //   q: 'Q11',
  //   id: ID.treatment_for_lung_cancer,
  //   type: SINGLE_OPTION_SELECT,
  //   section: SECTIONS.TREATMENT,
  //   bgIcon: i.treatment,
  //   order: 0,
  //   title: 'questionnaire.questions.lung.19_treatment_for_lung_cancer.title',

  //   info: {
  //     title: '',
  //     content: '',
  //   },
  //   options: yesAndNoOptions,
  //   conditions: [],
  //   isRequired: true,
  // },
  {
    q: 'Q15',
    id: ID.disease_measurable,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.lung.9_disease_measurable.title',

    info: {
      title: 'questionnaire.questions.lung.9_disease_measurable.info.title',
      content: 'questionnaire.questions.lung.9_disease_measurable.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.9_disease_measurable.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.lung.9_disease_measurable.options.no_but_it_can_be',
        value: 'no_but_it_can_be',
      },
      {
        label: 'questionnaire.questions.lung.9_disease_measurable.options.no',
        value: 'no',
      },
    ],
    conditions: [
      {
        ifId: ID.disease_status,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'remission',
      },
    ],
  },
  {
    q: 'Q16',
    id: ID.primary_tumor_size_mm,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.lung.10_primary_tumor_size_mm.title',
    subtitle: 'questionnaire.questions.lung.10_primary_tumor_size_mm.subtitle',
    info: {
      title: 'questionnaire.questions.lung.10_primary_tumor_size_mm.info.title',
      content: 'questionnaire.questions.lung.10_primary_tumor_size_mm.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
      {
        ifId: ID.disease_location,
        type: EQUAL_TO,
        ifValue: 'lungs',
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q17',
    id: ID.metastasis_size_mm,
    type: NUMBER_INPUT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.cancer_research,
    order: 0,
    title: 'questionnaire.questions.lung.11_metastasis_size_mm.title',
    subtitle: 'questionnaire.questions.lung.11_metastasis_size_mm.subtitle',
    info: {
      title: 'questionnaire.questions.lung.11_metastasis_size_mm.info.title',
      content: 'questionnaire.questions.lung.11_metastasis_size_mm.info.content',
    },
    options: [],
    conditions: [
      {
        ifId: ID.disease_measurable,
        type: EQUAL_TO,
        ifValue: 'yes',
      },
      {
        ifId: ID.current_stage,
        type: CONTAIN_ONE_OF,
        ifValue: ['4.1', '4.2'],
      },
    ],
    numberType: numberType.int,
  },
  {
    q: 'Q21',
    id: ID.has_biopsy,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.lung.12_has_biopsy.title',
    subtitle: 'questionnaire.questions.lung.12_has_biopsy.subtitle',
    info: {
      title: 'questionnaire.questions.lung.12_has_biopsy.info.title',
      content: 'questionnaire.questions.lung.12_has_biopsy.info.content',
    },
    options: [
      ...yesAndNoOptions,
      {
        label: 'questionnaire.questions.lung.12_has_biopsy.options.biopsy_possible',
        value: 'biopsy_possible',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q90',
    id: ID.pd_l1_carrier_status,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.lung.pd_l1_carrier_status.title',
    info: {
      title: 'questionnaire.questions.lung.pd_l1_carrier_status.info.title',
      content: 'questionnaire.questions.lung.pd_l1_carrier_status.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.pd_l1_carrier_status.options.positive',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.lung.pd_l1_carrier_status.options.negative',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.lung.pd_l1_carrier_status.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['no', 'biopsy_possible'],
      },
    ],
  },
  pdL1ExpressionByQNumber('Q90a'),
  {
    q: 'Q80',
    id: ID.ngs,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.lung.40_ngs.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.40_ngs.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.lung.40_ngs.options.yes_waiting_for_the_results',
        value: 'yes_waiting_for_the_results',
      },
      {
        label: 'questionnaire.questions.lung.40_ngs.options.no',
        value: 'no',
      },
      {
        label: 'questionnaire.questions.lung.40_ngs.options.no_but_planning',
        value: 'no_but_planning',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q22',
    id: BIOMARKERS_QUESTION_ID,
    type: BIOMARKERS,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.molecular,
    order: 0,
    title: 'questionnaire.questions.lung.13_biomarkers.title',
    subtitle: 'questionnaire.questions.lung.13_biomarkers.subtitle',
    placeholder: 'questionnaire.questions.biomarkers.placeholder',
    info: {
      title: '',
      content: '',
    },
    conditions: [
      {
        ifId: ID.has_biopsy,
        type: CONTAINS_ONE_OF_OR_SKIPPED,
        ifValue: ['yes'],
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q67',
    id: ID.braf_mutation,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: null,
    order: 0,
    title: 'questionnaire.questions.lung.41_braf_mutation.title',
    info: {
      title: 'questionnaire.questions.lung.41_braf_mutation.info.title',
      content: 'questionnaire.questions.lung.41_braf_mutation.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.41_braf_mutation.options.wildtype_braf',
        value: 'wildtype_braf',
      },
      {
        label: 'questionnaire.questions.lung.41_braf_mutation.options.braf_v600e',
        value: 'braf_v600e',
      },
      {
        label: 'questionnaire.questions.lung.41_braf_mutation.options.braf_v600d',
        value: 'braf_v600d',
      },
      {
        label: 'questionnaire.questions.lung.41_braf_mutation.options.braf_v600k',
        value: 'braf_v600k',
      },
      {
        label: 'questionnaire.questions.lung.41_braf_mutation.options.braf_v600r',
        value: 'braf_v600r',
      },
      {
        label: 'questionnaire.questions.lung.41_braf_mutation.options.braf_v600g',
        value: 'braf_v600g',
      },
      {
        label: 'questionnaire.questions.lung.41_braf_mutation.options.braf_v600m',
        value: 'braf_v600m',
      },
      {
        label: 'questionnaire.questions.lung.41_braf_mutation.options.braf_non_v600',
        value: 'braf_non_v600',
      },
    ],
    conditions: [
      {
        ifId: BIOMARKERS_QUESTION_ID,
        type: CONTAIN_ONE_OF,
        ifValue: ['braf'],
      },
    ],
  },
  {
    q: 'Q22a',
    id: ID.egft_mut,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.lung.14_egft_mut.title',

    info: {
      title: 'questionnaire.questions.lung.14_egft_mut.info.title',
      content: 'questionnaire.questions.lung.14_egft_mut.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.exon_19_del',
        value: 'exon_19_del',
      },
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.exon_20_ins',
        value: 'exon_20_ins',
      },
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.l858r',
        value: 'l858r',
      },
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.t790m',
        value: 't790m',
      },
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.g719s',
        value: 'g719s',
      },
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.g719x',
        value: 'g719x',
      },
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.l861q',
        value: 'l861q',
      },
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.g719a',
        value: 'g719a',
      },
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.c797s',
        value: 'c797s',
      },
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.s768i',
        value: 's768i',
      },
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.lung.14_egft_mut.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: BIOMARKERS_QUESTION_ID,
        type: CONTAIN_ONE_OF,
        ifValue: ['egfr'],
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q22b',
    id: ID.kras_mut,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.lung.15_kras_mut.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.15_kras_mut.options.g12c',
        value: 'g12c',
      },
      {
        label: 'questionnaire.questions.lung.15_kras_mut.options.g12v',
        value: 'g12v',
      },
      {
        label: 'questionnaire.questions.lung.15_kras_mut.options.g12d',
        value: 'g12d',
      },
      {
        label: 'questionnaire.questions.lung.15_kras_mut.options.g12a',
        value: 'g12a',
      },
      {
        label: 'questionnaire.questions.lung.15_kras_mut.options.g12s',
        value: 'g12s',
      },
      {
        label: 'questionnaire.questions.lung.15_kras_mut.options.g12f',
        value: 'g12f',
      },
      {
        label: 'questionnaire.questions.lung.15_kras_mut.options.g12r',
        value: 'g12r',
      },
      {
        label: 'questionnaire.questions.lung.15_kras_mut.options.g13c',
        value: 'g13c',
      },
      {
        label: 'questionnaire.questions.lung.15_kras_mut.options.g13d',
        value: 'g13d',
      },
      {
        label: 'questionnaire.questions.lung.15_kras_mut.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.lung.15_kras_mut.options.not_sure',
        value: 'not_sure',
      },
    ],
    conditions: [
      {
        ifId: BIOMARKERS_QUESTION_ID,
        type: CONTAIN_ONE_OF,
        ifValue: ['kras'],
      },
    ],
  },
  {
    q: 'Q22d',
    id: ID.erbb2_her2_mut,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.lung.41_erbb2_her2_mut.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.41_erbb2_her2_mut.options.exon_20_ins',
        value: 'exon_20_ins',
      },
      {
        label: 'questionnaire.questions.lung.41_erbb2_her2_mut.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: BIOMARKERS_QUESTION_ID,
        type: CONTAIN_ONE_OF,
        ifValue: ['erbb2_her2'],
      },
    ],
  },
  {
    q: 'Q22e',
    id: ID.met_mut,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_report,
    order: 0,
    title: 'questionnaire.questions.lung.42_c_met.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.42_c_met.options.met_exon_14_skip',
        value: 'met_exon_14_skip',
      },
      {
        label: 'questionnaire.questions.lung.42_c_met.options.met_amplification_overexpression',
        value: 'met_amplification_overexpression',
      },
      {
        label: 'questionnaire.questions.lung.42_c_met.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: BIOMARKERS_QUESTION_ID,
        type: CONTAIN_ONE_OF,
        ifValue: ['c_met'],
      },
    ],
  },
  {
    q: 'Q24',
    id: ID.smoking_state,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_CANCER,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.lung.43_smoking_state.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.43_smoking_state.options.yes',
        value: 'yes',
      },
      {
        label: 'questionnaire.questions.lung.43_smoking_state.options.quit',
        value: 'quit',
      },
      {
        label: 'questionnaire.questions.lung.43_smoking_state.options.no',
        value: 'no',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q12',
    id: ID.had_procedure_for_lung_cancer,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.lung.17_had_procedure_for_lung_cancer.title',

    info: {
      title: '',
      content: '',
    },
    options: yesAndNoOptions,
    conditions: [],
  },
  {
    q: 'Q70',
    id: ID.procedures,
    type: PROCEDURES,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.lung.18_procedures.title',
    subtitle: 'questionnaire.questions.lung.18_procedures.subtitle',
    footer: 'questionnaire.questions.lung.18_procedures.footer',
    requiredSubQuestionsId: [ID.procedures_received],
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.lung.18_procedures.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.lung.18_procedures.options.procedure_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.lung.18_procedures.options.procedure_purpose',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.lung.18_procedures.options.took_place_procedure',
        value: null,
      },
      {
        iconText: 'D',
        label: 'questionnaire.questions.lung.18_procedures.options.procedure_outcome',
        value: null,
      },
    ],
    shouldAutoOpenSubQuestions: [{
      ifId: ID.had_procedure_for_lung_cancer,
      type: EQUAL_TO,
      ifValue: 'yes',
    }],
    conditions: [
      {
        ifId: ID.had_procedure_for_lung_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    subQuestions: [
      {
        q: 'Q70a',
        id: ID.procedures_received,
        type: MULTIPLE_OPTION_SEARCH_SELECT,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.title',
        subtitle: '',
        placeholder: 'questionnaire.questions.procedures_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        options: {
          dropDownOptions: [
            {
              label:
                'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.options.pneumonectomy',
              value: 'pneumonectomy',
            },
            {
              label: 'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.options.lobectomy',
              value: 'lobectomy',
            },
            {
              label:
                'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.options.segmentectomy_wedge_resection',
              value: 'segmentectomy_wedge_resection',
            },
            {
              label:
                'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.options.sleeve_resection',
              value: 'sleeve_resection',
            },
            {
              label:
                'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.options.metastasis_resection',
              value: 'metastasis_resection',
            },
            {
              label: 'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.options.ablation',
              value: 'ablation',
            },
            {
              label: 'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.options.rfa',
              value: 'rfa',
            },
            {
              label: 'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.options.ebrt',
              value: 'ebrt',
            },
            {
              label:
                'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.options.brachytherapy',
              value: 'brachytherapy',
            },
            {
              label:
                'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.options.cranial_irradiation',
              value: 'cranial_irradiation',
            },
          ],
          radioOptions: [
            {
              label: 'questionnaire.questions.lung.18_procedures.sub_questions.procedures_received.options.other',
              value: 'other',
            },
          ],
        },
        conditions: [],
      },
      {
        q: 'Q70b',
        id: ID.procedure_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.lung.18_procedures.sub_questions.procedure_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q70c',
        id: ID.procedure_outcome,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.lung.18_procedures.sub_questions.procedure_outcome.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label: 'questionnaire.questions.lung.18_procedures.sub_questions.procedure_outcome.options.disease_free',
            value: 'disease_free',
          },
          {
            label:
              'questionnaire.questions.lung.18_procedures.sub_questions.procedure_outcome.options.local_residual_disease',
            value: 'local_residual_disease',
          },
          {
            label:
              'questionnaire.questions.lung.18_procedures.sub_questions.procedure_outcome.options.residual_systemic_disease',
            value: 'residual_systemic_disease',
          },
        ],
        answerResetable: true,
        conditions: [],
      },
    ],
  },

  {
    q: 'Q11a',
    id: ID.treatment_for_advanced_metastatic_disease,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.TREATMENT,
    bgIcon: i.treatment,
    order: 0,
    title: 'questionnaire.questions.lung.20_treatment_for_advanced_metastatic_disease.title',
    subtitle: 'questionnaire.questions.lung.20_treatment_for_advanced_metastatic_disease.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.treatment_for_lung_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
      {
        ifId: ID.current_stage,
        type: DOESNT_CONTAIN_ONE_OF_OR_SKIPPED,
        ifValue: ['0', '1', '2'],
      },
    ],
    isRequired: true,
  },
  {
    q: 'Q60',
    id: ID.treatments,
    type: TREATMENT_HISTORY,
    section: SECTIONS.TREATMENT,
    bgIcon: '',
    order: 0,
    title: 'questionnaire.questions.lung.21_treatments.title',

    requiredSubQuestionsId: [ID.drugs_received],
    footer: 'questionnaire.questions.lung.21_treatments.footer',
    info: {
      title: '',
      content: '',
    },
    submitButtonLabel: 'questionnaire.questions.lung.21_treatments.button.submit',
    options: [
      {
        iconText: 'A',
        label: 'questionnaire.questions.lung.21_treatments.options.treatment_name',
        value: null,
      },
      {
        iconText: 'B',
        label: 'questionnaire.questions.lung.21_treatments.options.course_received',
        value: null,
      },
      {
        iconText: 'C',
        label: 'questionnaire.questions.lung.21_treatments.options.cycles_received',
        value: null,
      },
      {
        iconText: 'D',
        label: 'questionnaire.questions.lung.21_treatments.options.when_treatment_stopped',
        value: null,
      },
      {
        iconText: 'E',
        label: 'questionnaire.questions.lung.21_treatments.options.reason_treatment_stopped',
        value: null,
      },
    ],
    // shouldAutoOpenSubQuestions: [{
    //   ifId: ID.treatment_for_lung_cancer,
    //   type: EQUAL_TO,
    //   ifValue: 'yes',
    // }],
    conditions: [
      {
        ifId: ID.treatment_for_lung_cancer,
        type: NOT_EQUAL_TO_OR_SKIPPED,
        ifValue: 'no',
      },
    ],
    isRequired: true,
    subQuestions: [
      {
        q: 'Q60a',
        id: ID.drugs_received,
        type: OPTIONS_FROM_SERVER,
        viewType: OPTIONS_LIST_WITH_SHOW_MORE,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'A',
        title: 'questionnaire.questions.lung.21_treatments.sub_questions.drugs_received.title',
        subtitle: 'questionnaire.questions.lung.21_treatments.sub_questions.drugs_received.subtitle',
        placeholder: 'questionnaire.questions.drugs_received.placeholder',
        info: {
          title: '',
          content: '',
        },
        questionProps: {
          isSearchableByOptionGroupName: true,
        },
      },
      {
        q: 'Q60f',
        id: ID.cycles,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        title: 'questionnaire.questions.lung.21_treatments.sub_questions.cycles.title',
        disableNextOnOptionSelect: false,
        options: [...treatmentCyclesOptions],
      },
      {
        q: 'Q60b',
        id: ID.treatment_admin,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: i.treatment,
        order: 0,
        titleIconText: 'B',
        title: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_admin.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: true,
        options: [
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_admin.options.neoadjuvant',
            value: 'neoadjuvant',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_admin.options.adjuvant',
            value: 'adjuvant',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_admin.options.systemic',
            value: 'systemic',
          },
        ],
        conditions: [],
      },
      {
        q: 'Q60c',
        id: ID.treatment_end_date,
        type: MONTH_PICKER,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'C',
        title: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_end_date.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        options: [],
        conditions: [],
        dateType: {
          type: dateType.stopTreatment,
        },
      },
      {
        q: 'Q60d',
        id: ID.treatment_stop_reason,
        type: SINGLE_OPTION_SELECT,
        section: SECTIONS.TREATMENT,
        bgIcon: '',
        order: 0,
        titleIconText: 'D',
        title: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.title',
        subtitle: '',
        info: {
          title: '',
          content: '',
        },
        disableNextOnOptionSelect: false,
        options: [
          {
            label:
              'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.treatment_ongoing',
            value: 'treatment_ongoing',
          },
          {
            label:
              'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.end_of_protocol',
            value: 'end_of_protocol',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.progression',
            value: 'progression',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.no_response',
            value: 'no_response',
          },
          {
            label:
              'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.loss_of_response',
            value: 'loss_of_response',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.remission',
            value: 'remission',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.toxicity',
            value: 'toxicity',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.other_medical_conditions',
            value: 'other_medical_conditions',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.dosing_issues',
            value: 'dosing_issues',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.scheduling',
            value: 'scheduling',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.travel_limitation',
            value: 'travel_limitation',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.financial_reasons_or_insurance',
            value: 'financial_reasons_or_insurance',
          },
          {
            label: 'questionnaire.questions.lung.21_treatments.sub_questions.treatment_stop_reason.options.other',
            value: 'other',
          },
        ],
        conditions: [],
      },
    ],
    // title: '',
    // info: {
    //   title: '',
    //   content: '',
    // },
    // footer: '',
    // type: TREATMENT_HISTORY,
    // section: SECTIONS.TREATMENT,
    // bgIcon: '',
    // order: 0,
    // conditions: [
    //   {
    //     ifId: ID.treatment_for_lung_cancer,
    //     type: NOT_EQUAL_TO_OR_SKIPPED,
    //     ifValue: 'no',
    //   },
    // ],
    // subQuestionsIds: [
    //   ID.treatment_for_advanced_metastatic_disease,
    //   ID.drugs_received,
    //   ID.treatment_stop_reason,
    //   ID.treatment_admin,
    // ],
    stageIds: [ID.current_stage],
    // surgeryId: ID.had_procedure_for_lung_cancer,
    // options: [],
    // subQuestionsOptions: {
    //   treatment_stop_reason: [
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.no_response',
    //       value: 'no_response',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.end_of_protocol',
    //       value: 'end_of_protocol',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.toxicity',
    //       value: 'toxicity',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.progression',
    //       value: 'progression',
    //     },
    //     {
    //       label:
    //         'questionnaire.questions.treatment_stop_reason.options.loss_of_response',
    //       value: 'loss_of_response',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.remission',
    //       value: 'remission',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatment_stop_reason.options.other',
    //       value: 'other',
    //     },
    //   ],
    //   taking_state: takingStateOptions,
    //   treatment_admin: [
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.neoadjuvant',
    //       value: 'neoadjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.adjuvant',
    //       value: 'adjuvant',
    //     },
    //     {
    //       label: 'questionnaire.questions.crc_2.treatments.sub_questions.treatment_admin.options.systemic',
    //       value: 'systemic',
    //     },
    //     {
    //       label: 'questionnaire.questions.treatments.options.i_dont_know',
    //       value: 'i_dont_know',
    //     },
    //   ],
    // },
  },
  {
    q: 'Q44',
    id: ID.current_treatment_systemic_steroids,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.22_current_treatment_systemic_steroids.title',
    subtitle: 'questionnaire.questions.lung.22_current_treatment_systemic_steroids.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.22_current_treatment_systemic_steroids.options.none',
        value: 'none',
      },
      {
        label: 'questionnaire.questions.lung.22_current_treatment_systemic_steroids.options.under_10_mg_daily',
        value: 'under_10_mg_daily',
      },
      {
        label: 'questionnaire.questions.lung.22_current_treatment_systemic_steroids.options.over_10_mg_daily',
        value: 'over_10_mg_daily',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q44a',
    id: ID.systemic_steroids_treatment_end_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.23_systemic_steroids_treatment_end_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [],
    conditions: [
      {
        ifId: ID.current_treatment_systemic_steroids,
        type: CONTAIN_ONE_OF,
        ifValue: ['under_10_mg_daily', 'over_10_mg_daily'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q45',
    id: ID.ecog,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.24_ecog.title',

    info: {
      title: 'questionnaire.questions.lung.24_ecog.info.title',
      content: 'questionnaire.questions.lung.24_ecog.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.24_ecog.options.0',
        value: '0',
      },
      {
        label: 'questionnaire.questions.lung.24_ecog.options.1',
        value: '1',
      },
      {
        label: 'questionnaire.questions.lung.24_ecog.options.2',
        value: '2',
      },
      {
        label: 'questionnaire.questions.lung.24_ecog.options.3',
        value: '3',
      },
      {
        label: 'questionnaire.questions.lung.24_ecog.options.4',
        value: '4',
      },
    ],
    conditions: [],
    isRequired: true,
  },
  {
    q: 'Q46',
    id: ID.other_medical_condition,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.25_other_medical_condition.title',
    subtitle: 'questionnaire.questions.lung.25_other_medical_condition.subtitle',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.no_other_health_issues',
        value: 'no_other_health_issues',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.liver',
        value: 'liver',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.heart',
        value: 'heart',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.kidney',
        value: 'kidney',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.autoimmune',
        value: 'autoimmune',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.mental',
        value: 'mental',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.allergies',
        value: 'allergies',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.chronic_infection_or_inflammation',
        value: 'chronic_infection_or_inflammation',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.gastrointestinal',
        value: 'gastrointestinal',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.other_cancer_type',
        value: 'other_cancer_type',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.anemia',
        value: 'anemia',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.thyroid',
        value: 'thyroid',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.hypertension',
        value: 'hypertension',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.epilepsy',
        value: 'epilepsy',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.diabetes',
        value: 'diabetes',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.organ_transplant',
        value: 'organ_transplant',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.extreme_obesity',
        value: 'extreme_obesity',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.stroke',
        value: 'stroke',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.lung',
        value: 'lung',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.neuropathy',
        value: 'neuropathy',
      },
      {
        label: 'questionnaire.questions.lung.25_other_medical_condition.options.bleeding_disorder',
        value: 'bleeding_disorder',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q48',
    id: ID.omc_liver,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.26_omc_liver.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.26_omc_liver.options.elevated_alt_ast',
        value: 'elevated_alt_ast',
      },
      {
        label: 'questionnaire.questions.lung.26_omc_liver.options.elevated_bilirubin',
        value: 'elevated_bilirubin',
      },
      {
        label: 'questionnaire.questions.lung.26_omc_liver.options.cirrhosis',
        value: 'cirrhosis',
      },
      {
        label: 'questionnaire.questions.lung.26_omc_liver.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['liver'],
      },
    ],
  },
  {
    q: 'Q49',
    id: ID.omc_heart,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.27_omc_heart.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.lung.27_omc_heart.options.class_at_least_nyha_ii',
        value: 'class_at_least_nyha_ii',
      },
      {
        label: 'questionnaire.questions.lung.27_omc_heart.options.condition_at_least_grade_2',
        value: 'condition_at_least_grade_2',
      },
      {
        label: 'questionnaire.questions.lung.27_omc_heart.options.cardiac_ventricular_arrhythmia',
        value: 'cardiac_ventricular_arrhythmia',
      },
      {
        label: 'questionnaire.questions.lung.27_omc_heart.options.heart_failure',
        value: 'heart_failure',
      },
      {
        label: 'questionnaire.questions.lung.27_omc_heart.options.lvef_under_50_percent',
        value: 'lvef_under_50_percent',
      },
      {
        label: 'questionnaire.questions.lung.27_omc_heart.options.qtcf_abnormal',
        value: 'qtcf_abnormal',
      },
      {
        label: 'questionnaire.questions.lung.27_omc_heart.options.angina',
        value: 'angina',
      },
      {
        label: 'questionnaire.questions.lung.27_omc_heart.options.myocardial_infarction',
        value: 'myocardial_infarction',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['heart'],
      },
    ],
  },
  {
    q: 'Q50',
    id: ID.omc_stroke_date,
    type: MONTH_PICKER,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.28_omc_stroke_date.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: '',
        value: '',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['stroke'],
      },
    ],
    dateType: {
      type: dateType.stopTreatment,
    },
  },
  {
    q: 'Q51',
    id: ID.omc_kidney,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.29_omc_kidney.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.lung.29_omc_kidney.options.low_glomerular_filtration_rate',
        value: 'low_glomerular_filtration_rate',
      },
      {
        label: 'questionnaire.questions.lung.29_omc_kidney.options.dialysis',
        value: 'dialysis',
      },
      {
        label: 'questionnaire.questions.lung.29_omc_kidney.options.kidney_stones',
        value: 'kidney_stones',
      },
      {
        label: 'questionnaire.questions.lung.29_omc_kidney.options.creatinine_high',
        value: 'creatinine_high',
      },
      {
        label: 'questionnaire.questions.lung.29_omc_kidney.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['kidney'],
      },
    ],
  },
  {
    q: 'Q52',
    id: ID.omc_autoimmune,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.30_omc_autoimmune.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.30_omc_autoimmune.options.congenital_or_acquired_immunosuppression',
        value: 'congenital_or_acquired_immunosuppression',
      },
      {
        label: 'questionnaire.questions.lung.30_omc_autoimmune.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.lung.30_omc_autoimmune.options.granulomatous_disease',
        value: 'granulomatous_disease',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['autoimmune'],
      },
    ],
  },
  {
    q: 'Q53',
    id: ID.omc_mental,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.31_omc_mental.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.lung.31_omc_mental.options.dementia',
        value: 'dementia',
      },
      {
        label: 'questionnaire.questions.lung.31_omc_mental.options.neuropsychiatric',
        value: 'neuropsychyatric',
      },
      {
        label: 'questionnaire.questions.lung.31_omc_mental.options.depression',
        value: 'depression',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['mental'],
      },
    ],
  },
  {
    q: 'Q54',
    id: ID.omc_allergy,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.32_omc_allergy.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.lung.32_omc_allergy.options.interferon',
        value: 'interferon',
      },
      {
        label: 'questionnaire.questions.lung.32_omc_allergy.options.metformin',
        value: 'metformin',
      },
      {
        label: 'questionnaire.questions.lung.32_omc_allergy.options.vitD',
        value: 'vitD',
      },
      {
        label: 'questionnaire.questions.lung.32_omc_allergy.options.penicillin',
        value: 'penicillin',
      },
      {
        label: 'questionnaire.questions.lung.32_omc_allergy.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['allergies'],
      },
    ],
  },
  {
    q: 'Q55',
    id: ID.omc_chronic_infection_inflammation,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.33_omc_chronic_infection_inflammation.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.lung.33_omc_chronic_infection_inflammation.options.hepatitis',
        value: 'hepatitis',
      },
      {
        label: 'questionnaire.questions.lung.33_omc_chronic_infection_inflammation.options.hiv',
        value: 'hiv',
      },
      {
        label: 'questionnaire.questions.lung.33_omc_chronic_infection_inflammation.options.other',
        value: 'other',
      },
      {
        label: 'questionnaire.questions.lung.33_omc_chronic_infection_inflammation.options.uncontrolled_infection',
        value: 'uncontrolled_infection',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['chronic_infection_or_inflammation'],
      },
    ],
  },
  {
    q: 'Q58',
    id: ID.omc_gastro_problem,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.34_omc_gastro_problem.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.lung.34_omc_gastro_problem.options.gastrointestinal_bleeding',
        value: 'gastrointestinal_bleeding',
      },
      {
        label: 'questionnaire.questions.lung.34_omc_gastro_problem.options.crohns',
        value: 'crohns',
      },
      {
        label: 'questionnaire.questions.lung.34_omc_gastro_problem.options.colitis',
        value: 'colitis',
      },
      {
        label: 'questionnaire.questions.lung.34_omc_gastro_problem.options.ibd',
        value: 'ibd',
      },
      {
        label: 'questionnaire.questions.lung.34_omc_gastro_problem.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['gastrointestinal'],
      },
    ],
  },
  {
    q: 'Q59',
    id: ID.omc_lung_disease,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.35_omc_lung_disease.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      noneOption,
      {
        label: 'questionnaire.questions.lung.35_omc_lung_disease.options.asthma',
        value: 'asthma',
      },
      {
        label: 'questionnaire.questions.lung.35_omc_lung_disease.options.interstitial_lung_disease',
        value: 'interstitial_lung_disease',
      },
      {
        label: 'questionnaire.questions.lung.35_omc_lung_disease.options.pneumonitis',
        value: 'pneumonitis',
      },
      {
        label: 'questionnaire.questions.lung.35_omc_lung_disease.options.dyspnea',
        value: 'dyspnea',
      },
      {
        label: 'questionnaire.questions.lung.35_omc_lung_disease.options.tuberculosis',
        value: 'tuberculosis',
      },
      {
        label: 'questionnaire.questions.lung.35_omc_lung_disease.options.other',
        value: 'other',
      },
    ],
    conditions: [
      {
        ifId: ID.other_medical_condition,
        type: CONTAIN_ONE_OF,
        ifValue: ['lung'],
      },
    ],
  },
  {
    q: 'Q56',
    id: ID.omc_abnormal_blood_results,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.36_omc_abnormal_blood_results.title',

    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.36_omc_abnormal_blood_results.options.none',
        value: 'none',
        exclusive: true,
      },
      {
        label: 'questionnaire.questions.lung.36_omc_abnormal_blood_results.options.low_wbc',
        value: 'low_wbc',
      },
      {
        label: 'questionnaire.questions.lung.36_omc_abnormal_blood_results.options.low_hemoglobin',
        value: 'low_hemoglobin',
      },
      {
        label: 'questionnaire.questions.lung.36_omc_abnormal_blood_results.options.low_neutrophil_count',
        value: 'low_neutrophil_count',
      },
      {
        label: 'questionnaire.questions.lung.36_omc_abnormal_blood_results.options.low_platelet_count',
        value: 'low_platelet_count',
      },
      {
        label: 'questionnaire.questions.lung.36_omc_abnormal_blood_results.options.low_potassium',
        value: 'low_potassium',
      },
      {
        label: 'questionnaire.questions.lung.36_omc_abnormal_blood_results.options.low_magnesium',
        value: 'low_magnesium',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q47',
    id: ID.other_medications,
    type: MULTIPLE_OPTION_SELECT,
    section: SECTIONS.MY_HEALTH,
    bgIcon: i.doctor_book_kit,
    order: 0,
    title: 'questionnaire.questions.lung.37_other_medications.title',
    subtitle: 'questionnaire.questions.lung.37_other_medications.subtitle',
    info: {
      title: 'questionnaire.questions.lung.37_other_medications.info.title',
      content: 'questionnaire.questions.lung.37_other_medications.info.content',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.37_other_medications.options.cyp3a4_inhibitors',
        value: 'cyp3a4_inhibitors',
      },
      {
        label: 'questionnaire.questions.lung.37_other_medications.options.cyp3a4_inducers',
        value: 'cyp3a4_inducers',
      },
      {
        label: 'questionnaire.questions.lung.37_other_medications.options.immunosuppressive_medications',
        value: 'immunosuppressive_medications',
      },
      {
        label: 'questionnaire.questions.lung.37_other_medications.options.warfarin',
        value: 'warfarin',
      },
      {
        label: 'questionnaire.questions.lung.37_other_medications.options.anticonvulsants',
        value: 'anticonvulsants',
      },
      {
        label: 'questionnaire.questions.lung.37_other_medications.options.anti_retroviral_therapy',
        value: 'anti_retroviral_therapy',
      },
      {
        label: 'questionnaire.questions.lung.37_other_medications.options.none',
        value: 'none',
        exclusive: true,
      },
    ],
    conditions: [],
  },
  initialDiagnosisDate('Q5'),
  userType,
  dateOfBirth,
  {
    q: 'Q2',
    id: ID.gender,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.lung.0_gender.title',
    info: {
      title: '',
      content: '',
    },
    options: [
      {
        label: 'questionnaire.questions.lung.0_gender.options.female',
        value: 'female',
      },
      {
        label: 'questionnaire.questions.lung.0_gender.options.male',
        value: 'male',
      },
      {
        label: 'questionnaire.questions.global.gender.options.other',
        value: 'other',
      },
    ],
    conditions: [],
  },
  {
    q: 'Q3',
    id: ID.is_pregnant,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.lung.38_is_pregnant.title',

    info: {
      title: 'questionnaire.questions.lung.38_is_pregnant.info.title',
      content: 'questionnaire.questions.lung.38_is_pregnant.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.gender,
        type: CONTAIN_ONE_OF,
        ifValue: ['female'],
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  {
    q: 'Q4',
    id: ID.is_breastfeeding,
    type: SINGLE_OPTION_SELECT,
    section: SECTIONS.ABOUT_ME,
    bgIcon: i.medical_profile,
    order: 0,
    title: 'questionnaire.questions.lung.39_is_breastfeeding.title',

    info: {
      title: 'questionnaire.questions.lung.39_is_breastfeeding.info.title',
      content: 'questionnaire.questions.lung.39_is_breastfeeding.info.content',
    },
    options: yesAndNoOptions,
    conditions: [
      {
        ifId: ID.gender,
        type: EQUAL_TO,
        ifValue: 'female',
      },
      {
        type: AGE_LESS_THAN,
        ifValue: 50,
      },
    ],
  },
  race,
  haveInsurance,
  insurance,
  preferredLocation,
  travelLimit,
];

export default Questions;
