import React from 'react';
import { Trans } from 'react-i18next';
import './SponsoredTrialInfo.css';
import { MAIN_WEBSITE_LINKS } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';

const SponsoredTrialInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="sponsored-info-popup">
      <p>{t('sponsored_trial_info.part_1')}</p>
      <ul className="sponsored-info-list">
        <li>{t('sponsored_trial_info.list_item_1')}</li>
        <li>{t('sponsored_trial_info.list_item_2')}</li>
      </ul>
      <p>{t('sponsored_trial_info.part_2')}</p>
      <p>
        <Trans
          i18nKey="sponsored_trial_info.part_3"
          components={{
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            privacyLink: <a
              className="sponsored-info-link"
              href={MAIN_WEBSITE_LINKS.PRIVACY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
            />,
          }}
        />
      </p>
    </div>
  );
};

export default SponsoredTrialInfo;
