import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { conditionalClasses } from 'helpers';
import ArrowIcon from 'new-ui/assets/icons/arrow.svg';
import PropTypes from 'prop-types';
import InfoNote from 'new-ui/Results/ResultList/InfoNote/InfoNote';
import TreatmentItem from 'new-ui/Treatment/Item';
import { randomString } from 'new-ui/functions';

const SearchTreatmentList = ({ treatmentsCategories, itemData }) => {
  const { t } = useTranslation();
  const [expandedTreatments, setExpandedTreatments] = useState([0]);
  const [closedNotes, setClosedNotes] = useState([]);
  const {
    action, data
  } = itemData;

  const toggleTreatment = (index) => {
    if (!expandedTreatments.includes(index)) {
      setClosedNotes(closedNotes.filter((i) => i !== index));
    }
    setExpandedTreatments(
      expandedTreatments.includes(index)
        ? expandedTreatments.filter((i) => i !== index)
        : [...expandedTreatments, index],
    );
  };

  const onNoteClose = (index) => {
    setClosedNotes([...closedNotes, index]);
  };

  const getInfoBoxTranslation = (key) => {
    const trans = `match.list.treatment.category.description.${key}`;
    const translated = t(trans);
    if (trans === translated) return '';
    return translated;
  };

  // debugger;

  return (
    <div className="treatment-list">
      {treatmentsCategories?.map(({ treatment_category_key, treatment_category_description_key, treatments }, index) => (
        <div
          key={index}
          className={conditionalClasses({
            collapsed: !expandedTreatments.includes(index),
          })}
        >
          <div
            id={`item-${index}`}
            onClick={() => toggleTreatment(index)}
            className="treatment-header list-item"
          >
            <span className="arrow"><img src={ArrowIcon} alt="arrow" /></span>
            <p
              className="title"
            >
              {t(`match.list.treatment.category.title.${treatment_category_key}`)}

            </p>
            <span className="index">{treatments.length}</span>
          </div>
          <div className="collapse-container">
            {getInfoBoxTranslation(treatment_category_description_key)
              && (
                <InfoNote
                  closed={closedNotes.includes(index)}
                  onClose={() => onNoteClose(index)}
                  isExpanded
                  info={getInfoBoxTranslation(treatment_category_description_key)}
                />
              )}
            {
              treatments.map((item, idx)=>{
                return (
                  <TreatmentItem
                    key={randomString()}
                    listItem
                    item={item}
                    number={idx + 1}
                    action={action}
                    data={data}
                    title={item.ctgov_title}
                    isSearchPage
                  />
                );
              })
            }
          </div>

        </div>
      ))}
    </div>
  );
};

SearchTreatmentList.propTypes = {
  treatmentsCategories: PropTypes.array,
  children: PropTypes.any,
};

export default SearchTreatmentList;
