import React from 'react';
import SponsoredTrialInfo from 'new-ui/Trial/Item/SponsoredTrialInfo';
import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';

export const getTitle = (title, item) => {
  if (title) return title;
  if (item.groups.length) {
    return item.groups.join('<span>, </span>');
  }
  return '';
};

export const openSponseredTrialInfoPopup = () => {
  app.setPopup(POPUP_TYPES.GENERAL, {
    title: window.t('sponsored_trial_info.title'),
    content: <SponsoredTrialInfo />,
    button: {
      title: window.t('sponsored_trial_info.popup_button'),
      action: (closePopup) => {
        closePopup();
      },
    },
  });
};
