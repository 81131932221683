import CoPayIcon from '../category-images/co_pay.svg';
import MentalHealthSupport from '../category-images/mental_health_support.svg';
import MealAndFoodSupport from '../category-images/meal_and_food_support.svg';
import Support from '../category-images/support.svg';
import SupportGroup from '../category-images/support_groups.svg';
import FinancialSupport from '../category-images/financial_support.svg';
import Lodging from '../category-images/lodging.svg';
import MedicalExpenses from '../category-images/medical_expenses.svg';
import Transportation from '../category-images/transportation.svg';
import TravelExpenses from '../category-images/travel_expenses.svg';
import CaregiverSupport from '../category-images/caregiver_support.svg';
import Legal from '../category-images/legal.svg';
import PetCareSupport from '../category-images/pet_care_support.svg';
import ChildCareSupport from '../category-images/child_care_support.svg';

const categoriesMap = {
    "Co-Pay": {
        name: "co_pay",
        label: "Co-Pay",
        icon: CoPayIcon,
        description: "Assistance provided to help cover the out-of-pocket costs that patients are required to pay for their medical treatments, medications, and doctor visits, which are not fully covered by insurance",
        selected: false
    },
    "Mental Health Support": {
        name: "mental_health_support",
        label: "Mental Health Support",
        icon: MentalHealthSupport,
        description: "Financial aid or services that cover counseling, therapy, and other mental health services to help patients and their families cope with the emotional and psychological challenges of a cancer diagnosis and treatment",
        selected: false
    },
    "Pet Care Support": {
        name: "pet_care_support",
        label: "Pet Care Support",
        icon: PetCareSupport,
        description: "Financial assistance or services that help with the care of a patient's pets, including costs for pet sitting, veterinary care, and feeding, ensuring that pets are well-cared for during the patient's treatment and recovery periods",
        selected: false
    },
    "Meal & Food Support": {
        name: "meal_and_food_support",
        label: "Meal & Food Support",
        icon: MealAndFoodSupport,
        description: "Programs that provide meals, groceries, or financial aid to purchase food for cancer patients who may be unable to prepare meals themselves due to treatment side effects or financial constraints",
        selected: false
    },
    "Support": {
        name: "support",
        label: "Support",
        icon: Support,
        description: "General assistance that might include a variety of services or financial aid to help patients with various needs that arise during their cancer journey",
        selected: false
    },
    "Support Group": {
        name: "support_group",
        label: "Support Group",
        icon: SupportGroup,
        description: "Financial support for joining or forming support groups where patients and their families can share experiences, receive emotional support, and gain practical advice from others going through similar situations",
        selected: false
    },
    "Financial Support": {
        name: "financial_support",
        label: "Financial Support",
        icon: FinancialSupport,
        description: "Broad financial aid that helps cover various costs associated with cancer treatment, including medical bills, living expenses, and other related costs that can cause financial strain",
        selected: false
    },
    "Lodging": {
        name: "lodging",
        label: "Lodging",
        icon: Lodging,
        description: "Assistance with temporary housing costs for patients and their families who need to travel far from home for treatment, ensuring they have a comfortable and affordable place to stay during their treatment period",
        selected: false
    },
    "Medical Expenses": {
        name: "medical_expenses",
        label: "Medical Expenses",
        icon: MedicalExpenses,
        description: "Financial aid specifically targeted at covering medical costs, including hospital bills, medications, medical equipment, and other treatment-related expenses",
        selected: false
    },
    "Transportation": {
        name: "transportation",
        label: "Transportation",
        icon: Transportation,
        description: "Programs that provide financial aid or services for transportation to and from treatment facilities, ensuring patients can attend their appointments without the added stress of transportation costs",
        selected: false
    },
    "Travel Expenses": {
        name: "travel_expenses",
        label: "Travel Expenses",
        icon: TravelExpenses,
        description: "Financial support for travel-related costs such as airfare, gas, or public transportation for patients who need to travel long distances to receive treatment",
        selected: false
    },
    "Caregiver Support": {
        name: "caregiver_support",
        label: "Caregiver Support",
        icon: CaregiverSupport,
        description: "Assistance for those providing care to cancer patients, including financial aid, respite care, training, and resources to support caregivers in managing their responsibilities",
        selected: false
    },
    "Legal Assistance": {
        name: "legal_assistance",
        label: "Legal Assistance",
        icon: Legal,
        description: "Financial aid or services that help patients with legal issues related to their diagnosis, such as navigating insurance claims, employment rights, advance directives, and other legal matters",
        selected: false
    },
    "Medical Support": {
        name: "medical_support",
        label: "Medical Support",
        icon: Support,
        description: "General financial aid for various medical-related needs that may not be covered under specific categories, including specialist consultations, second opinions, and medical supplies",
        selected: false
    },
    "Child Care Support": {
        name: "child_care_support",
        label: "Child Care Support",
        icon: ChildCareSupport,
        description: "Financial assistance or services that help cover the costs of child care for patients undergoing treatment, ensuring their children are cared for while they focus on their recovery",
        selected: false
    }
};

const countProgramsByCategory = (programs) => {
    const programsCount = {};
    for(let program of programs) {
        const categoryName = program.category.toLowerCase();
        if(programsCount.hasOwnProperty(categoryName)) {
            programsCount[categoryName]++;
        }else {
            programsCount[categoryName]=1;
        }
    };
    return programsCount;
};

const arrangeCategories = (categories, programs, existingCategories) => {
    const totalCategory = categories.map(categoryName => categoriesMap[categoryName]);
    const programsCount = countProgramsByCategory(programs);

    totalCategory.map(category => {
        const label = category.label.toLowerCase();
        category.selected = existingCategories?.length ? existingCategories.map(el => el.toLowerCase()).includes(label) : true;
        category.program_count = programsCount[label];
        return category;
    });
    return totalCategory;
};

const saveCategoriesToLocalStorage = (categoryOptions) => {
    localStorage.setItem('categoryOptions', JSON.stringify(categoryOptions));
};
const getCategoriesFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('categoryOptions'));
};

const clearCategoriesFromLocalStorage = () => {
    localStorage.removeItem('categoryOptions');
};

const getCategoriesWithoutOnboarding = (categories) => {
    const fromLocalStorage = getCategoriesFromLocalStorage();
    if(!fromLocalStorage) {
      return categories;
    }
    const selectedCategories = new Set(fromLocalStorage.map(cat => cat.label));
    return categories.map(category => ({
        ...category,
        selected: selectedCategories.has(category.label),
    }));
};

const getCategoryLabels = () => {
    const categories = getCategoriesFromLocalStorage() || [];
    return categories.map(el => el.label);
}

export {
    categoriesMap,
    arrangeCategories,
    saveCategoriesToLocalStorage,
    getCategoriesFromLocalStorage,
    getCategoriesWithoutOnboarding,
    clearCategoriesFromLocalStorage,
    getCategoryLabels
}