import React, {
  useState, useEffect,
} from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import PropTypes from 'prop-types';
import './PatientsLikeYou.css';
import LinkButton from 'new-ui/Components/LinkButton';
import { fetchTreatmentExperience } from 'new-ui/Treatment/api';
import { COLORS } from 'new-ui/constants';
import Preloader from 'new-ui/Components/Preloader';
import IconNotIntense from './assets/not_intense.svg';
import IconSomewhatIntense from './assets/somewhat_intense.svg';
import IconVeryIntense from './assets/very_intense.svg';

export const EXPERIENCE_DATA_TYPES = {
  AGGRESSION: 'aggression',
  SIDE_EFFECTS: 'side_effects',
  TREATMENT_OUTCOME: 'treatment_outcome',
};

export const AGGRESSION_TYPES = {
  NOT_AGGRESSIVE: 'not_aggressive',
  NOT_RELEVANT: 'not_relevant',
  SOMEWHAT_AGGRESSIVE: 'somewhat_aggresive',
  VERY_AGGRESSIVE: 'very_aggressive',
};

const getDataByType = (data, type) => {
  const d = data?.items?.filter((b) => b?.type === type);
  return d?.length ? d[0] : null;
};

const PatientsLikeYou = (props) => {
  const { treatment } = props;

  const { t } = useTranslation();

  const [collapsed, setCollapsed] = useState(true);
  const [barItems, setBarItems] = useState(null);
  const [sideEffects, setSideEffects] = useState(null);
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [effectsAll, setEffectsAll] = useState(null);

  function handleBarItemWidth(arr) {
    return arr.some((item) => parseInt(item.width, 10) > 0);
  }

  useEffect(() => {
    setIsLoading(true);
    const fetch = async () => {
      const treatmentExperienceData = await fetchTreatmentExperience(treatment.nct_number);

      if (!treatmentExperienceData) return;

      setData(true);

      const aggressionData = getDataByType(treatmentExperienceData, EXPERIENCE_DATA_TYPES.AGGRESSION);
      const notAggressive = getDataByType(aggressionData, AGGRESSION_TYPES.NOT_AGGRESSIVE);
      const somewhatAggressive = getDataByType(aggressionData, AGGRESSION_TYPES.SOMEWHAT_AGGRESSIVE);
      const veryAggressive = getDataByType(aggressionData, AGGRESSION_TYPES.VERY_AGGRESSIVE);
      const bi = [];
      if (notAggressive) {
        bi.push({
          width: `${notAggressive.percent}%`,
          label: `${Math.round(notAggressive.percent)}%`,
          text: t('patients_like_you.not_intense'),
          icon: IconNotIntense,
        });
      } else {
        bi.push({
          width: '0%',
          label: '0%',
          text: t('patients_like_you.not_intense'),
          icon: IconNotIntense,
        });
      }
      if (somewhatAggressive) {
        bi.push({
          width: `${somewhatAggressive.percent}%`,
          label: `${Math.round(somewhatAggressive.percent)}%`,
          text: t('patients_like_you.somewhat_intense'),
          icon: IconSomewhatIntense,
        });
      } else {
        bi.push({
          width: '0%',
          label: '0%',
          text: t('patients_like_you.somewhat_intense'),
          icon: IconSomewhatIntense,
        });
      }
      if (veryAggressive) {
        bi.push({
          width: `${veryAggressive.percent}%`,
          label: `${Math.round(veryAggressive.percent)}%`,
          text: t('patients_like_you.very_intense'),
          icon: IconVeryIntense,
        });
      } else {
        bi.push({
          width: '0%',
          label: '0%',
          text: t('patients_like_you.very_intense'),
          icon: IconVeryIntense,
        });
      }
      setBarItems(bi);

      const sideEffectsData = getDataByType(treatmentExperienceData, EXPERIENCE_DATA_TYPES.SIDE_EFFECTS);
      if (sideEffectsData) {
        const allSideEffects = sideEffectsData?.items.filter((b) => b.count >= 1).sort((b, c) => (b.percent >= c.percent ? -1 : 1));
        const se = allSideEffects.map((s) => ({
          title: t(`side_effects.${s.title}`),
          v: s.percent,
        }));
        setEffectsAll(se);
        setSideEffects(se.slice(0, 10));
      }
      setIsLoading(false);
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatment, setIsLoading]);

  if (isLoading) {
    return <Preloader inline isVisible />;
  }

  return (data && (barItems || sideEffects) && handleBarItemWidth(barItems)
    ? (
      <div className="widget patients-like-you">
        <div className="community-section">
          <div className="title">{t('patients_like_you.leal_community')}</div>
          <div className="subtitle">{t('soc_page.community')}</div>
          <div className="intensity-title">{t('patients_like_you.intensity')}</div>
        </div>
        {barItems && (
          <div className="widget-body">
            <div className="intensity-block">
              {barItems.map((intense, index) => (
                <div key={index}>
                  <img src={intense.icon} alt={intense.text} />
                  <div className="intensity-text">{intense.text}</div>
                  <div className="intensity-percent">
                    {intense.width}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {(sideEffects && sideEffects.length) && (
          <div className={`widget-body side-effects ${collapsed ? 'collapsed' : ''}`}>
            <div className="title">{t('soc_page.side_effects')}</div>
            {collapsed ? sideEffects.map((sideEffect, key) => (
              <div className="side-effect" key={key}>
                <div className="side-effect-title">{sideEffect.title}</div>
                <div className="side-effects-section">
                  <div className="side-effects-section-percent">
                    <div className="side-effects-section-text">
                      {parseFloat(sideEffect.v.toFixed(1))}
                      %
                    </div>
                    <div className="show-percent" style={{ width: `${sideEffect.v}%` }} />
                  </div>
                </div>
              </div>
            )) : effectsAll.map((sideEffect, key) => (
              <div className="side-effect" key={key}>
                <div className="side-effect-title">{sideEffect.title}</div>
                <div className="side-effects-section">
                  <div className="side-effects-section-percent">
                    <div className="side-effects-section-text">
                      {parseFloat(sideEffect.v.toFixed(1))}
                      %
                    </div>
                    <div className="show-percent" style={{ width: `${sideEffect.v}%` }} />
                  </div>
                </div>
              </div>
            ))}
            {collapsed && effectsAll.length > 10 && (
              <LinkButton color={COLORS.primaryButton} title={t('patients_like_you.see_more')} action={() => { setCollapsed(false); }} />
            )}
          </div>
        )}
      </div>

    )
    : null);
};

PatientsLikeYou.propTypes = {
  treatment: PropTypes.any,
};

export default PatientsLikeYou;
