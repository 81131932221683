/* eslint-disable no-unused-vars */
import React from 'react';
import './HeaderTabs.css';

import { ReactComponent as SupportIcon } from 'new-ui/assets/icons/support.svg';
import { ReactComponent as TreatmentIcon } from 'new-ui/assets/icons/treatment_icon.svg';
import NewIcon from 'new-ui/assets/icons/new.svg';

import { PAGES, URI } from 'new-ui/constants';
import app from 'new-ui/app';
import { RESULT_LIST_TABS } from 'new-ui/Results/ResultList';
import { GA_HCP } from 'new-ui/Search/GA';
import { useTranslation } from 'utils/modifiedTranslation';
import { createUserFinance } from 'new-ui/Pages/Finance';

const GA = {
  category: 'Finance',
  events: {
    clickFinancialTab: 'Clicking on the “Financial support” tab',
    clickTreatmentTab: 'Clicking on the “Treatment options” tab',
  },
};

const HeaderTabs = (props)=>{
  const {
    isHcp = app.isCurrentPage(PAGES.PUBLIC_FINANCE) || app.isCurrentPage(PAGES.SEARCH) || app.isCurrentPage(PAGES.PATIENTS),
    isSearchPage = app.isCurrentPage(PAGES.PUBLIC_FINANCE) || app.isCurrentPage(PAGES.SEARCH),
  } = props;

  const { t } = useTranslation();

  const gotoTab = (tabName)=>{
    if (app.isCurrentPage(PAGES.RESULTS)) app.setTab(tabName);
    else app.history.push(`${URI[PAGES.RESULTS]}?tab=${tabName}`);
  };

  return (
    <div className="header-tabs">
      <div
        className={`header-tab ${
          app.isCurrentPage(PAGES.RESULTS)
          || app.isCurrentPage(PAGES.SEARCH)
            ? 'header-tab-selected' : ''
        } header-tab-results`}
        onClick={()=>{
          if (app.isCurrentPage(PAGES.FINANCE) && !app.user) {
            createUserFinance({
              redirectTo: URI[PAGES.RESULTS],
              subtitle: t('support_programs.create_user_subtitle_header'),
            });
            return;
          }
          if (isSearchPage || isHcp) {
            app.history.push(URI[PAGES.SEARCH]);
            app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.TREATMENTS_TAB_CLICKED);
          } else gotoTab(RESULT_LIST_TABS.TRIALS);
        }}
      >
        <div className="header-tab-icon">
          <TreatmentIcon />
        </div>
        <div
          className="header-tab-title"
          onClick={()=>{
            app.sendGoogleAnalyticsEvent(GA.category, GA.events.clickTreatmentTab, { name: 'Treatment options tab' });
          }}
        >
          {t('general.treatment_options')}
        </div>
      </div>
      <div
        className={`header-tab ${app.isCurrentPage(PAGES.FINANCE) || app.isCurrentPage(PAGES.PUBLIC_FINANCE) ? 'header-tab-selected' : ''} header-tab-finance`}
        onClick={()=>{
          if (isSearchPage || isHcp) {
            app.history.push(URI[PAGES.PUBLIC_FINANCE]);
            app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.FINANCE_TAB_CLICKED);
          } else app.history.push(URI[PAGES.FINANCE]);
          app.sendGoogleAnalyticsEvent(GA.category, GA.events.clickFinancialTab, { name: 'Financial support tab clicked' });
        }}
      >
        <div className="header-tab-icon">
          <SupportIcon />
        </div>
        <div className="header-tab-title">{t('general.support_programs')}</div>
        <img className="header-tab-new" src={NewIcon} alt="none" />
      </div>
    </div>
  );
};

export default HeaderTabs;
