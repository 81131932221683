import React from 'react';
import './style.css';
import { Button } from '@material-ui/core';
import ArrowLeft from 'new-ui/assets/icons/arrow_left_2.svg';
import app from 'new-ui/app';
import { PAGES, URI } from 'new-ui/constants';
import SupportPrograms from '../SupportPrograms';
import FinanceCategoryCards from '../FinanceCategoryCards';

const FilteredPrograms = ({
  programs, categories, onBackClick, GA,
}) => {
  return (
    <div className="filtered-programs">
      <div className="header">
        <Button onClick={onBackClick} className="back-btn">
          <img className="btn-icon" src={ArrowLeft} alt="icon" />
          <span className="btn-label">Back</span>
        </Button>
        <Button onClick={() => app.history.push(URI[PAGES.FINANCE_INTAKE])} className="check-btn">
          <span className="btn-title">Check eligibility</span>
        </Button>
      </div>
      <div className="programs-wrapper">
        {categories?.length && <FinanceCategoryCards GA={GA} options={categories} onSelectionChange={()=>{}} />}
        <SupportPrograms programs={programs} GA={GA} />
      </div>
    </div>
  );
};

export default FilteredPrograms;
