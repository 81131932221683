import React, { useState, useEffect } from 'react';
import app from 'new-ui/app';
import { useParams } from 'react-router-dom';
import { getTrialByNCT } from 'new-ui/api';
import { urlToNct } from 'new-ui/functions';
import PointsToDiscussWithOncologist from 'new-ui/Widgets/PointsToDiscuss';
import PatientsLikeYou from 'new-ui/Widgets/PatientsLikeYou';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TreatmentItem from './Item';

const PublicTreatment = () => {
  const { nctNumber } = useParams();
  const history = useHistory();
  const [treatment, setTreatment] = useState([...(app.treatments || [])].find((i) => i.nct_number === urlToNct(nctNumber)));
  const isSearchPage = history.location.pathname.includes('treatment');
  app.treatment = treatment; // debugging purposes

  useEffect(() => {
    const fetch = async () => {
      const usersCountry = app.user?.info?.country?.location;
      const r = await getTrialByNCT({ nctNumber, usersCountry });
      setTreatment(r);
    };
    if (!treatment || !treatment?.points_to_discuss_with_your_oncologist) fetch();
  }, [nctNumber, treatment]);

  return (
    treatment ? (
      <div className={isSearchPage ? 'treatment isSearchPage' : 'treatment '}>
        <div className="wrapper">

          <TreatmentItem
            isFull
            item={treatment}
            type="list"
            friendlyTitle={treatment.title ?? null}
          />

          <div className="height-20" />
          <div className="height-20" />
          <PatientsLikeYou treatment={treatment} />
          {
            treatment.points_to_discuss_with_your_oncologist ? <PointsToDiscussWithOncologist treatment={treatment} /> : null
          }

          {/* {renderActions('mobile')} */}
          <div className="height-20" />
          <div className="height-20" />
        </div>
        {/* {renderActions('desktop')} */}
      </div>
    ) : null
  );
};

export default PublicTreatment;
