import CancerType from './cancerTypes';

export const treatmentForIndicationQuestionIds = {
  [CancerType.AML]: 'treatment_for_aml',
  [CancerType.BLADDER]: 'treatment_for_bladder_cancer',
  [CancerType.BLADDER_2]: 'treatment_for_bladder_cancer',
  [CancerType.MELANOMA_2]: 'treatment_for_melanoma',
  [CancerType.CRC]: 'treatment_for_crc_cancer',
  [CancerType.CRC_2]: 'treatment_for_crc_cancer',
  [CancerType.MDS]: 'treatment_for_mds',
  [CancerType.MPN]: 'treatment_for_mpn',
  [CancerType.UTERINE]: 'treatment_for_uterine_cancer',
  [CancerType.BRAIN]: 'treatment_for_brain_cancer',
  [CancerType.MM]: 'treatment_for_mm',
  [CancerType.LUNG]: 'treatment_for_lung_cancer',
  [CancerType.BREAST_2]: 'treatment_for_breast_cancer',
  [CancerType.NHL]: 'treatment_for_lymphoma',
  [CancerType.PROSTATE]: 'treatment_for_prostate_cancer',
  [CancerType.HNSCC]: 'treatment_for_hnscc',
  [CancerType.CLL]: 'treatment_for_cll',
  [CancerType.GI_TRACT]: 'treatment_for_gi_tract',
  [CancerType.OVARIAN]: 'treatment_for_ovarian_cancer',
  [CancerType.HCC]: 'treatment_for_liver',
  [CancerType.RCC]: 'treatment_for_kidney_cancer',
  [CancerType.MESO]: 'treatment_for_mesothelioma_cancer',

};

export const procedureForIndicationQuestionIds = {
  bladder_2: 'had_surgery_for_bladder_cancer',
  breast_2: 'had_procedure_for_breast_cancer',
  crc_2: 'had_procedure_for_crc_cancer',
  gi_tract: 'had_procedure_for_gi_cancer',
  hcc: 'had_procedure',
  hnscc: 'had_procedure',
  lung: 'had_procedure_for_lung_cancer',
  nhl: 'had_procedure_for_lymphoma',
  ovarian: 'had_procedure_for_ovarian_cancer',
  prostate: 'had_procedure_for_prostate_cancer',
  uterine: 'had_procedure_for_uterine_cancer',
  rcc_kidney: 'had_procedure_for_kidney_cancer',
  mesothelioma: 'had_procedure_for_mesothelioma_cancer',
};
