import React from 'react';
import './Statistics.css';
import { useTranslation } from 'utils/modifiedTranslation';

const Statistics = () => {
  const { t } = useTranslation();

  const statisticsData = [
    { count: t('upload.statistic1.count'), text: t('upload.statistic1.text') },
    { count: t('upload.statistic2.count'), text: t('upload.statistic2.text') },
    { count: t('upload.statistic3.count'), text: t('upload.statistic3.text') },
    { count: t('upload.statistic4.count'), text: t('upload.statistic4.text') },
  ];

  return (
    <div className="statistics">
      {statisticsData.map((data, key)=>{
        return (
          <div key={key} className="statistics-block">
            <div className="statistics-round">{data.count}</div>
            <div className="statistics-text">{data.text}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Statistics;
