/* eslint-disable arrow-parens */
/* eslint-disable operator-linebreak */
// eslist-disable

import React, { useEffect, useState } from 'react';
import app from 'new-ui/app';
import GenericChat from '../../Components/GenericChat';
import { updateChatScoreUsingThumb } from '../Chat/functions';
import { countNonNullValues, extractNonNullValuesInfo, handleSendMessage } from './functions';

const ChatUploadPopup = () => {
  const [init, setInit] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState(app.chatHistory || []);
  const userWixId = app.user.info.user_wix_id;
  const indication = app.user.personal.condition;
  const userConditionProfile = app.user.condition_profile;
  const numOfExtractedInfo = countNonNullValues(userConditionProfile);
  const extractedInfo = extractNonNullValuesInfo(userConditionProfile);

  const manageToExtractDrugsReceived =
    userConditionProfile.drugs_received &&
    Array.isArray(userConditionProfile.drugs_received) &&
    userConditionProfile.drugs_received.length > 0;

  const msg = manageToExtractDrugsReceived
    ? ` Well done! We have extracted ${numOfExtractedInfo} pieces of information from your documents. Please review them. Do you need our help to understand them? \n 
   In addition, we have extracted information about your medications. We kindly ask you to order your treatments from your diagnosis date to today. 
   \n Let me know if you need any further assistance.
   `
    : ` Well done! We have extracted  ${numOfExtractedInfo} pieces of information from your documents. Please review them. \n
    Unfortunately, we could not find any treatment information in your document. Please note that you can upload more files later on.`;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (localStorage.getItem('chatUploadExplainSeen') === '1') {
      app.chatHistory = null;
      localStorage.removeItem('chatUploadExplainSeen');
    }
    setChatHistory(
      app.chatHistory || [
        {
          role: 'Jane (patient navigator)',
          content: `${msg}`,
          daynamicContent: null,
        },
      ],
    );
    setInit(true);
  }, []);

  // eslist-disable
  useEffect(() => {
    const $chatPopup = document.querySelector('.chat-popup');
    if ($chatPopup) {
      $chatPopup.scrollTop = $chatPopup.scrollHeight;
    }
    app.chatHistory = chatHistory;
  }, [chatHistory]);

  const handleSendMessageClick = async prompt => {
    await handleSendMessage(prompt, chatHistory, setChatHistory, userWixId, indication, setLoading, extractedInfo);
  };

  return init ? (
    <GenericChat
      handleSendMessageClick={handleSendMessageClick}
      updateChatScoreUsingThumb={updateChatScoreUsingThumb}
      chatHistory={chatHistory}
    />
  ) : null;
};

export default ChatUploadPopup;
