/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';

import app from 'new-ui/app';
import deleteIcon from 'new-ui/assets/icons/delete_icon.svg';
import './editTreatment.css';
import { getDrugsTranslatedLabel } from 'new-ui/functions/getDrugsTranslatedLabel';
import { useTranslation } from 'react-i18next';
import { advancedMetastaticDiseaseOptions, metastaticId } from 'components/Questionnaire/components/TreatmentQuestion/constants';
import { profileTreatmentSectionEvents, GA_CATEGORY_NAME } from 'new-ui/Components/GoogleAnalytics/profileSectionEvents';
import SelectV2 from '../Form/Select.v2';

const {
  treatmentEdited,
  treatmentDeleted,
} = profileTreatmentSectionEvents;

export const EditTreatment = ({
  treatment = {},
  treatments = [],
  setDrawerContent,
  treatmentStopReasonOptions,
  treatmentAdminOptions,
  metastaticValue,
  shouldMetastaticQuestionBeShown,
}) => {
  const { t } = useTranslation();
  const [treatmentsFields, setTreatmentsFields] = useState({ ...treatment });
  const [advancedMetastaticDisease, setAdvancedMetastaticDisease] = useState(Array.isArray(metastaticValue)
    ? metastaticValue[0] : metastaticValue);
  const treatmentStopReasonValue = Array.isArray(treatmentsFields.treatment_stop_reason)
    ? treatmentsFields.treatment_stop_reason[0] : treatmentsFields.treatment_stop_reason;
  const treatmentAdminValue = Array.isArray(treatmentsFields.treatment_admin)
    ? treatmentsFields.treatment_admin[0] : treatmentsFields.treatment_admin;

  const updateQuestions = (questions) => {
    (async () => {
      await app.answerQuestion(questions);
    })();
    setDrawerContent(null);
  };
  const yearsOptions = useMemo(() => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = 1930; year <= currentYear; year++) {
      years.push({
        title: year,
        value: `${year}`,
      });
    }
    return years;
  }, []);

  const deleteTreatment = () => {
    const filteredTreatments = treatments.filter((treat) => treat.id !== treatment.id);
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_NAME,
      treatmentDeleted.eventName,
      treatmentDeleted.getMetaData(),
    );
    updateQuestions({
      treatments: filteredTreatments,
    });
  };

  const updateTreatment = () => {
    const filteredTreatments = treatments.map((treat) => {
      if (treat.id === treatment.id) {
        treat = treatmentsFields;
      }
      return treat;
    });
    let questionsToUpdate = {
      treatments: filteredTreatments,
    };

    if (shouldMetastaticQuestionBeShown) {
      questionsToUpdate = {
        ...questionsToUpdate,
        [metastaticId]: advancedMetastaticDisease,
      };
    }
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_NAME,
      treatmentEdited.eventName,
      treatmentEdited.getMetaData(),
    );

    updateQuestions(
      questionsToUpdate,
    );
  };

  return (
    <div className="edit-treatment">
      <div className="edit-treatment-label">
        <span>{getDrugsTranslatedLabel(t, treatment.drugs_received)}</span>
        <img onClick={deleteTreatment} src={deleteIcon} alt="deleteTreatment" />
      </div>

      {treatmentStopReasonOptions?.length > 0 && (
        <div className="edit-treatment-input-wrapper">
          <p>{t('questionnaire.profile_treatments.edit_treatment.outcome')}</p>
          <SelectV2
            options={treatmentStopReasonOptions}
            onChange={(treat) => setTreatmentsFields({
              ...treatmentsFields,
              treatment_stop_reason: treat[0],
            })}
            value={treatmentStopReasonValue ? [treatmentStopReasonValue] : []}
          />
        </div>
      )}

      {shouldMetastaticQuestionBeShown && (
        <div className="edit-treatment-input-wrapper">
          <p>{t('questionnaire.profile_treatments.edit_treatment.metastatic')}</p>
          <SelectV2
            options={advancedMetastaticDiseaseOptions}
            onChange={(treat) => setAdvancedMetastaticDisease(treat[0])}
            value={advancedMetastaticDisease ? [advancedMetastaticDisease] : []}
          />
        </div>
      )}

      {treatmentAdminOptions?.length > 0 && (
        <div className="edit-treatment-input-wrapper">
          <p>{t('questionnaire.profile_treatments.edit_treatment.type')}</p>
          <SelectV2
            options={treatmentAdminOptions}
            onChange={(treat) => setTreatmentsFields({
              ...treatmentsFields,
              treatment_admin: treat[0],
            })}
            value={treatmentAdminValue ? [treatmentAdminValue] : []}
          />
        </div>
      )}

      <div className="edit-treatment-input-wrapper">
        <p>{t('questionnaire.profile_treatments.edit_treatment.finishing_date')}</p>
        <SelectV2
          options={yearsOptions}
          onChange={(treat) => {
            setTreatmentsFields({
              ...treatmentsFields,
              treatment_end_date: `${treat[0]}-01-01`,
            });
          }}
          value={
            treatmentsFields.treatment_end_date
              ? [`${new Date(treatmentsFields.treatment_end_date).getFullYear()}`]
              : []
          }
        />
      </div>

      <button className="edit-treatment-update-btn" type="button" onClick={updateTreatment}>
        {t('questionnaire.profile_treatments.edit_treatment.update_btn')}
      </button>
    </div>
  );
};
