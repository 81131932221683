import React, {
  useState,
} from 'react';
import './IntakeAboutYou.css';

import PropTypes from 'prop-types';

import DateInput from 'new-ui/Components/Form/Date';
import moment from 'moment';
import { hasGenderQuestion } from 'new-ui/functions';
import { useTranslation } from 'utils/modifiedTranslation';
import Button from 'new-ui/Components/Button';
import { ReactComponent as IconGenderMale } from './assets/gender_male.svg';
import { ReactComponent as IconGenderFemale } from './assets/gender_female.svg';
import { ReactComponent as IconGenderTumtum } from './assets/gender_tumtum.svg';
import { ReactComponent as IconPatient } from './assets/Patient.svg';
import { ReactComponent as IconCaregiver } from './assets/Caregiver.svg';
import { ReactComponent as IconOncologist } from './assets/Oncologist.svg';
import { ReactComponent as IconOther } from './assets/Other.svg';
import IconTick from './assets/tick-circle.svg';
import MultipleOptionSelect from '../MultipleOptionSelect';

export const USER_TYPES = {
  PATIENT: 'patient',
  CAREGIVER: 'caregiver',
  ONCOLOGIST: 'oncologist',
  OTHER: 'other',
};

export const USER_TYPES_VALUES = {
  [USER_TYPES.PATIENT]: 'patient',
  [USER_TYPES.CAREGIVER]: 'caregiver',
  [USER_TYPES.ONCOLOGIST]: 'doctor',
  [USER_TYPES.OTHER]: 'other',
};

const USER_TYPES_ICONS = {
  [USER_TYPES.PATIENT]: <IconPatient />,
  [USER_TYPES.CAREGIVER]: <IconCaregiver />,
  [USER_TYPES.ONCOLOGIST]: <IconOncologist />,
  [USER_TYPES.OTHER]: <IconOther />,
};

const GENDERS = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other',
};

const GENDERS_ICONS = {
  [GENDERS.MALE]: <IconGenderMale />,
  [GENDERS.FEMALE]: <IconGenderFemale />,
  [GENDERS.OTHER]: <IconGenderTumtum />,
};

const RACE_OPTIONS = [
  { label: 'questionnaire.questions.global.race.options.native_american', value: 'native_american' },
  { label: 'questionnaire.questions.global.race.options.asian', value: 'asian' },
  { label: 'questionnaire.questions.global.race.options.african_american', value: 'african_american' },
  { label: 'questionnaire.questions.global.race.options.hispanic_latino', value: 'hispanic_latino' },
  { label: 'questionnaire.questions.global.race.options.pacific_islander', value: 'pacific_islander' },
  { label: 'questionnaire.questions.global.race.options.white', value: 'white' },
  { label: 'questionnaire.questions.global.race.options.other', value: 'other' },
  { label: 'questionnaire.questions.global.race.options.decline_to_answer', value: 'decline_to_answer', exclusive: true },
];

const IntakeAboutYou = (props) => {
  const {
    user,
    onselect,
  } = props;
  const { t } = useTranslation();
  const [userType, setUserType] = useState(user.info.user_type);
  const [userGender, setGender] = useState(user.personal.gender ? (Array.isArray(user.personal.gender) ? user.personal.gender[0] : user.personal.gender) : null);
  const [userDob, setDob] = useState(user.personal.dob ? new Date(user.personal.dob) : null);
  const [userRace, setRace] = useState(user.condition_profile.race);

  const TypeButton = (type) => (
    <div>
      <div
        onClick={() => {
          setUserType(type);
          user.info.user_type = USER_TYPES_VALUES[type];
          onselect(user, true);
        }}
        className={`about-you-icon ${userType === USER_TYPES_VALUES[type] ? 'selected' : ''}`}
      >
        {USER_TYPES_ICONS[type]}
      </div>
      <div className="about-you-icon-title">
        {type}
      </div>
    </div>
  );

  const GenderButton = (gender) => (
    <div>
      <div
        onClick={() => {
          setGender(gender);
          user.personal.gender = gender;
          user.condition_profile.gender = gender;
          onselect(user, true);
        }}
        className={`about-you-icon gender-button ${userGender === gender ? 'selected' : ''}`}
      >
        {GENDERS_ICONS[gender]}
        { userGender === gender ? <img alt="icon" src={IconTick} /> : null}
      </div>
      <div className="about-you-icon-title">
        {gender}
      </div>
    </div>
  );

  return (
    <div className="intake-about-you">
      <div className="intake-question-title">
        {t('intake.about_title')}
      </div>
      <div className="about-you-part">
        <div className="about-you-title">
          {t('intake.about_user_type_title')}
        </div>
        <div className="about-you-icons user-type flex">
          { TypeButton(USER_TYPES.PATIENT) }
          { TypeButton(USER_TYPES.CAREGIVER) }
          { TypeButton(USER_TYPES.ONCOLOGIST) }
          { TypeButton(USER_TYPES.OTHER) }
        </div>
      </div>
      {
        hasGenderQuestion() ? (
          <div className="about-you-part">
            <div className="about-you-title">
              {t('intake.about_gender_title')}
            </div>
            <div className="about-you-icons user-gender flex">
              { GenderButton(GENDERS.MALE) }
              { GenderButton(GENDERS.FEMALE) }
              { GenderButton(GENDERS.OTHER) }
            </div>
          </div>
        ) : null
      }
      <div className="about-you-part">
        <div className="about-you-title">
          {t('intake.about_dob_title')}
        </div>
        <div className="user-age flex">
          <DateInput
            value={userDob}
            placeholder="MM/YYYY"
            formatter={(date) => moment(date).format('MM/YYYY')}
            hideOnSelect
            monthPickerMode
            onChange={(date) => {
              setDob(date);
              user.personal.dob = moment(date).format('YYYY-MM-DD');
              user.condition_profile.dob = date.toISOString();
              onselect(user, true);
            }}
          />
        </div>
      </div>
      <div className="about-you-part">
        <div className="about-you-title">
          {t('intake.about_race_title')}
        </div>
        <div className="user-race flex">
          <MultipleOptionSelect
            initValue={userRace}
            singleValues={['decline_to_answer']}
            autoChange
            onselect={(race) => {
              setRace(race);
              user.condition_profile.race = (race && race.length) ? race : null;
              onselect(user, true);
            }}
            q={
              {
                options: RACE_OPTIONS,
              }
            }
          />
        </div>
      </div>
      <div className="height-20" />
      <Button
        title={t('general.save')}
        action={()=>{
          onselect(user);
        }}
      />
    </div>
  );
};

IntakeAboutYou.propTypes = {
  q: PropTypes.any,
  user: PropTypes.any,
  onselect: PropTypes.func,
};

export default IntakeAboutYou;
