/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable arrow-parens */
/* eslint-disable prefer-template */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';
import scanDate from '../../../assets/icons/scan_date.svg';
import scheduleACall from '../../../assets/icons/schedule_a_call.svg';
import updateProfile from '../../../assets/icons/update_profile.svg';
import shareWithYourDoctor from '../../../assets/icons/share_with_doctor.svg';
import { scheduleACallHandler, shareWithYourDoctorHandler, updateNextScanHandler } from '../functions';
import './ActionPanel.css';

/**
 * Renders an action panel for selecting a topic, with buttons for different actions.
 *
 * @param {Object} props - The component's props
 * @param {Object} props.loading - Indicates whether the panel is in a loading state
 * @param {Function} props.setUserChatMode - Sets the user's chat mode
 * @return {JSX.Element} The action panel component
 */

const ActionPanel = ({ loading, setUserChatMode, userHaveSponseredTrialList, setChatHistory, setShowChatNextScan }) => {
  const handleChatActionButtonClicked = (mode, index, callback) => {
    // if (setUserChatMode != null) {
    //   setUserChatMode(mode);
    // }

    if (index != null) {
      const clickedButton = document.querySelector(`[data-key="${index}"]`);
      if (clickedButton != null) {
        clickedButton.classList.add('chat-active-action-panel-button');
      }

      const otherButtons = document.querySelectorAll('.chat-actions-panel-button');
      if (otherButtons != null) {
        otherButtons.forEach(button => {
          if (button !== clickedButton) {
            button.classList.remove('chat-active-action-panel-button');
          }
        });
      }

      if (callback != null) {
        callback();
      }
    }
  };

  const lastActionButton = userHaveSponseredTrialList => {
    let button;
    if (userHaveSponseredTrialList) {
      button = {
        icon: scheduleACall,
        alt: 'Schedule A Call',
        label: 'Schedule A Call',
        mode: '2',
        callback: () =>
          scheduleACallHandler(
            'Our team would love to help you, just schedule a call in the following link: ',
            userHaveSponseredTrialList,
            setChatHistory,
            setUserChatMode,
            setShowChatNextScan
          ),
      };
    } else {
      button = {
        icon: updateProfile,
        alt: 'Update profile',
        label: 'Update profile',
        mode: '2',
        callback: () => app.setPopup(POPUP_TYPES.MEDICAL_PROFILE),
      };
    }

    return button;
  };

  const [chatActionsButtons, setChatActionsButtons] = useState([]);
  useEffect(() => {
    if (setChatActionsButtons != null) {
      setChatActionsButtons([
        {
          icon: scanDate,
          alt: 'Update Scan Date',
          label: 'Update Scan Date',
          mode: '2',
          callback: () => updateNextScanHandler(null, setShowChatNextScan, setChatHistory, true),
        },
        {
          icon: shareWithYourDoctor,
          alt: 'Share with your doctor',
          label: 'Share with your doctor',
          mode: '2',
          callback: () => shareWithYourDoctorHandler('', setChatHistory, setUserChatMode, setShowChatNextScan),
        },
        lastActionButton(userHaveSponseredTrialList),
      ]);
    }
    /* eslint-disable-next-line */
  }, []);

  return (
    <div className="chat-actions-panel">
      <div className="chat-actions-panel-buttons">
        {chatActionsButtons.map((button, index) => {
          return (
            <button
              key={index}
              data-key={index}
              type="button"
              disabled={loading}
              title={button.alt}
              className="chat-actions-panel-button"
              onClick={() => {
                handleChatActionButtonClicked(button.mode, index, button.callback);
              }}
            >
              <img className="chat-actions-panel-icon" src={button.icon} alt={button.alt} />
              <br />
              {button.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ActionPanel;
