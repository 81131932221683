import React from 'react';
import './Tags.css';

import deleteIcon from 'new-ui/assets/icons/delete_x.svg';
import PropTypes from 'prop-types';

export const Tag = (props)=>{
  const {
    title,
    value,
    action,
  } = props;
  return (
    <div
      className="tag"
      onClick={()=>{
        action(value);
      }}
    >
      <div className="tag-title">
        {title}
      </div>
      <div className="tag-button">
        <img src={deleteIcon} alt="icon" />
      </div>
    </div>
  );
};

Tag.propTypes = {
  title: PropTypes.any,
  value: PropTypes.any,
  action: PropTypes.any,
};

const Tags = (props)=>{
  const {
    items = [],
  } = props;
  return (
    <div className="tags">
      {
        items.map((item, k)=><Tag key={k} {...item} />)
      }
    </div>
  );
};

Tags.propTypes = {
  items: PropTypes.any,
};

export default Tags;
