/* eslint-disable no-continue */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-console */
/* eslint-disable prefer-template */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */

import { formatTextToUseNewLineBeforeNumbers } from '../Chat/functions';
import { sendMessage } from './api';

const updateChatHistory = (setChatHistory, chatHistory, content, role) => {
  setChatHistory(chatHistory => [
    ...chatHistory,
    {
      role: role ?? 'Jane (patient navigator)',
      content: ` ${formatTextToUseNewLineBeforeNumbers(content)}`,
    },
  ]);
};

export const handleSendMessage = async (
  prompt,
  chatHistory,
  setChatHistory,
  setLoading,
) => {
  setLoading(true);

  if (prompt) {
    updateChatHistory(setChatHistory, chatHistory, ` ${formatTextToUseNewLineBeforeNumbers(prompt)}`, 'Me');

    const response = await sendMessage(prompt);

    updateChatHistory(
      setChatHistory,
      chatHistory,
      ` ${formatTextToUseNewLineBeforeNumbers(response.msg)}`,
      'Jane (patient navigator)'
    );

    try {
    } catch (error) {
      updateChatHistory(
        setChatHistory,
        chatHistory,
        'Our System is busy. <br> please again resubmit our question',
        'Jane (patient navigator)'
      );
    } finally {
      setLoading(false);
    }
  }
};
