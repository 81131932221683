/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  GA_CHAT_EVENTS,
  GA_UPLOAD_CHAT_EVENTS,
  GA_UPLOAD_EXPLAIN_CHAT_EVENTS,
} from 'new-ui/Components/GoogleAnalytics/generalEvents';
import './ChatButton.css';

import app from 'new-ui/app';
import { CHAT_INDICATIONS, PAGES, POPUP_TYPES } from 'new-ui/constants';

import messageChatIcon from 'new-ui/assets/icons/message_chat.svg';
import { isMoreThan30DaysApart } from './functions';

const ChatButton = ({ popupType, text, isVisible }) => {
  useEffect(() => {
    const updateGAForOnChatView = async (popupType) => {
      switch (popupType) {
        case POPUP_TYPES.CHAT:
          app.sendGoogleAnalyticsEvent(GA_CHAT_EVENTS.PROFILE_CHAT_VIEW_BY_USER, 'user views profile chat');
          break;
        case POPUP_TYPES.CHAT_UPLOAD:
          localStorage.setItem('chatUploadSeen', '1');
          app.sendGoogleAnalyticsEvent(GA_UPLOAD_CHAT_EVENTS.UPLOAD_CHAT_VIEW_BY_USER, 'user views upload chat');
          break;
        case POPUP_TYPES.CHAT_UPLOAD_EXPLAIN:
          localStorage.setItem('chatUploadExplainSeen', '1');
          app.sendGoogleAnalyticsEvent(
            GA_UPLOAD_EXPLAIN_CHAT_EVENTS.UPLOAD_EXPLAIN_CHAT_VIEW_BY_USER,
            'user views explain chat',
          );
          break;
        default:
          break;
      }
      app.sendGoogleAnalyticsEvent(GA_CHAT_EVENTS.PROFILE_CHAT_OPEN, 'user opened profile chat');
    };

    updateGAForOnChatView(popupType);
  }, [popupType]);

  if (
    !isVisible
    && ((!app.isCurrentPage(PAGES.RESULTS)
      && !app.isCurrentPage(PAGES.TRIAL)
      && !app.isCurrentPage(PAGES.TREATMENT)
      && !app.isCurrentPage(PAGES.UPLOAD))
      || !app?.user?.personal?.condition
      || !CHAT_INDICATIONS.includes(app.user.personal.condition))
  ) {
    document.body.classList.remove('chat-mode');
    return null;
  }
  document.body.classList.add('chat-mode');

  const updateGAForClickingOnChatButton = async (popupType) => {
    switch (popupType) {
      case POPUP_TYPES.CHAT:
        app.sendGoogleAnalyticsEvent(GA_CHAT_EVENTS.PROFILE_CHAT_OPEN, 'user opened profile chat');
        break;
      case POPUP_TYPES.CHAT_UPLOAD:
        app.sendGoogleAnalyticsEvent(GA_UPLOAD_CHAT_EVENTS.UPLOAD_CHAT_OPEN, 'user opened upload chat');
        break;
      case POPUP_TYPES.CHAT_UPLOAD_EXPLAIN:
        app.sendGoogleAnalyticsEvent(
          GA_UPLOAD_EXPLAIN_CHAT_EVENTS.UPLOAD_EXPLAIN_CHAT_OPEN,
          'user upload explain chat',
        );
        break;
      default:
        break;
    }
    app.sendGoogleAnalyticsEvent(GA_CHAT_EVENTS.PROFILE_CHAT_OPEN, 'user opened profile chat');
  };

  return (
    <div
      className="chat-button"
      onClick={async () => {
        app.setPopup(popupType ?? POPUP_TYPES.CHAT, {
          preventOverlayClose: true,
        });
        await updateGAForClickingOnChatButton(popupType);

        if (popupType !== POPUP_TYPES.CHAT_UPLOAD_EXPLAIN) {
          const date = Date.now();
          app.coniguration.data.chat_return_user = isMoreThan30DaysApart(
            app?.coniguration?.data?.interaction_with_smart_chat ?? null,
            date,
          );
          app.coniguration.data.interaction_with_smart_chat = Date.now();

          await app.setConfiguration(app.coniguration.data);
        }
      }}
    >
      <div className="chat-button-container">
        <div className="chat-button-icon">
          <img src={messageChatIcon} alt="open chat" />
        </div>
        {text ?? 'Understand treatments'}
      </div>
    </div>
  );
};

export default ChatButton;
