import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import request from 'functions/api/request';

const apiUrl = configs.beApiUrl;
const { financeApiUrl } = configs;

const searchApiCache = {};
let lastCallback = null;

export const getSearchApi = async (data, cb) => {
  let response = null;

  const cacheKey = JSON.stringify(data);
  const cached = searchApiCache[cacheKey];

  if (cached) {
    if (cached === 'pending') return;
    return cb ? cb(cached) : cached;
  }

  searchApiCache[cacheKey] = 'pending';

  if (cb) lastCallback = cb;

  const requestOptions = {
    url: `${apiUrl}/public/trials/searchTrials`,
    data,
    json: true,
    timeout: 60 * 60 * 1000,
  };

  try {
    response = await request.post(requestOptions);
    searchApiCache[cacheKey] = response;
    if (cb) {
      if (lastCallback) {
        if (lastCallback === cb) return cb(response);
        return;
      }
      return cb(response);
    }
  } catch (ex) {
    console.error('Error occurred, while getting search results');
    return { error: ex.message } || null;
  }

  return response;
};

export const hcpApi = {

  addPatient: async (patient)=>{
    const patientUserWixId = patient.info.user_wix_id;
    let response = null;

    const requestOptions = {
      url: `${apiUrl}/user/care-assistant/patient`,
      headers: await authHeaders(),
      data: { patientUserWixId },
      json: true,
    };

    try {
      response = await request.post(requestOptions);
    } catch (ex) {
      console.error('Error occurred, while adding patient to existing user:', ex);
      throw ex;
    }

    return response;
  },

  updateUser: async (patient)=>{
    const patientUserWixId = patient.info.user_wix_id;
    let response = null;

    if (patient?.info?.country?.name) patient.info.country.label = patient.info.country.name;

    const requestOptions = {
      url: `${apiUrl}/user/care-assistant/patient/${patientUserWixId}`,
      headers: await authHeaders(),
      data: { ...patient },
      json: true,
    };

    try {
      response = await request.put(requestOptions);
    } catch (ex) {
      console.error('Error occurred, while updating existing patient:', ex);
      throw ex;
    }

    return response;
  },

  user2params: async (user)=>{
    let response = null;

    const requestOptions = {
      url: `${apiUrl}/user/public-profile-from-standard-profile`,
      headers: await authHeaders(),
      data: { ...user },
      json: true,
    };

    try {
      response = await request.post(requestOptions);
    } catch (ex) {
      console.error('Error occurred, while getting user2params:', ex);
      throw ex;
    }

    return response;
  },

  params2user: async (params)=>{
    let response = null;

    const data = { ...params };

    const requestOptions = {
      url: `${apiUrl}/user/standard-profile-from-public-profile`,
      headers: await authHeaders(),
      data,
      json: true,
    };

    try {
      response = await request.post(requestOptions);
    } catch (ex) {
      console.error('Error occurred, while getting params for user :', ex);
      throw ex;
    }

    return response;
  },

  registerFinanceCaregiver: async ()=>{
    let response = null;

    const requestOptions = {
      url: `${financeApiUrl}/webapp/care-assistants`,
      headers: await authHeaders(),
      data: {
        role: 'caregiver',
      },
      json: true,
    };

    try {
      response = await request.post(requestOptions);
    } catch (ex) {
      console.error('Error occurred, while adding patient to existing user:', ex);
      throw ex;
    }

    return response;
  },

  createFinancePatient: async (patientFinanceData)=>{
    let response = null;

    delete patientFinanceData.email;
    delete patientFinanceData.location;

    const requestOptions = {
      url: `${financeApiUrl}/webapp/care-assistants/patients`,
      headers: await authHeaders(),
      data: { ...patientFinanceData },
      json: true,
    };

    try {
      response = await request.post(requestOptions);
    } catch (ex) {
      console.error('Error occurred, while adding patient to existing user:', ex);
      throw ex;
    }

    return response;
  },

  getFinanceCaregiver: async ()=>{
    let response = null;

    const requestOptions = {
      url: `${financeApiUrl}/webapp/care-assistants/me`,
      headers: await authHeaders(),
      json: true,
    };

    try {
      response = await request.get(requestOptions);
    } catch (ex) {
      console.error('Error occurred, while adding patient to existing user:', ex);
      throw ex;
    }

    return response;
  },

  getFinancePatient: async (patientUserWixId)=>{
    let response = null;

    const requestOptions = {
      url: `${financeApiUrl}/webapp/care-assistants/patients/${patientUserWixId}`,
      headers: await authHeaders(),
      json: true,
    };

    try {
      response = await request.get(requestOptions);
    } catch (ex) {
      console.error('Error occurred, while adding patient to existing user:', ex);
      throw ex;
    }

    return response;
  },

  updateFinancePatient: async (patient, patientFinanceData = {})=>{
    const patientWixId = patient?.info?.user_wix_id;
    if (!patientWixId) throw Error('No wixId supplied to updateFinancePatient');
    let response = null;

    delete patientFinanceData.userWixId;
    delete patientFinanceData.email;
    delete patientFinanceData.location;

    const requestOptions = {
      url: `${financeApiUrl}/webapp/care-assistants/patients/${patientWixId}`,
      headers: await authHeaders(),
      data: { ...patientFinanceData },
      json: true,
    };

    try {
      response = await request.put(requestOptions);
    } catch (ex) {
      console.error('Error occurred, while adding patient to existing user:', ex);
      throw ex;
    }

    return response;
  },

  financeMatchAndSave: async (patient, patientFinanceData)=>{
    // /webapp/care-assistants/patients/6694fad8e24ebae515cf3b37/programs/match
    const patientWixId = patient?.info?.user_wix_id;
    if (!patientWixId) throw Error('No wixId supplied to updateFinancePatient');
    let response = null;

    const requestOptions = {
      url: `${financeApiUrl}/webapp/care-assistants/patients/${patientWixId}/programs/match`,
      headers: await authHeaders(),
      data: { ...patientFinanceData },
      json: true,
    };

    try {
      response = await request.post(requestOptions);
    } catch (ex) {
      console.error('Error occurred, while adding patient to existing user:', ex);
      throw ex;
    }

    return response;
  },

  financeMatch: async (financeData)=>{
    delete financeData.userWixId;
    delete financeData.email;

    if (!financeData.stage) financeData.stage = null;

    let response = null;

    const requestOptions = {
      url: `${financeApiUrl}/webapp/programs/match/public`,
      data: { ...financeData },
      json: true,
    };

    try {
      response = await request.post(requestOptions);
    } catch (ex) {
      console.error('Error occurred, while getting public finance match:', ex);
      throw ex;
    }

    return response.matchedPrograms;
  },

};

window.__hcpApi = hcpApi;
