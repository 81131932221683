/* eslint-disable no-continue */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-console */
/* eslint-disable prefer-template */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */

import { formatTextToUseNewLineBeforeNumbers } from '../Chat/functions';
import { sendMessage } from './api';

const updateChatHistory = (setChatHistory, chatHistory, content, role) => {
  setChatHistory(chatHistory => [
    ...chatHistory,
    {
      role: role ?? 'Jane (patient navigator)',
      content: ` ${formatTextToUseNewLineBeforeNumbers(content)}`,
    },
  ]);
};

export const handleSendMessage = async (
  prompt,
  chatHistory,
  setChatHistory,
  userWixId,
  indication,
  setLoading,
  extractedInfo
) => {
  setLoading(true);

  if (prompt) {
    updateChatHistory(setChatHistory, chatHistory, ` ${formatTextToUseNewLineBeforeNumbers(prompt)}`, 'Me');

    const response = await sendMessage(prompt, userWixId, indication, extractedInfo);

    updateChatHistory(
      setChatHistory,
      chatHistory,
      ` ${formatTextToUseNewLineBeforeNumbers(response.msg)}`,
      'Jane (patient navigator)'
    );

    try {
    } catch (error) {
      updateChatHistory(
        setChatHistory,
        chatHistory,
        'Our System is busy. <br> please again resubmit our question',
        'Jane (patient navigator)'
      );
    } finally {
      setLoading(false);
    }
  }
};

export const countNonNullValues = obj => {
  let count = 0;
  for (const key in obj) {
    // Skip the specified keys
    if (
      key === 'profile_id'
      || key === 'temp_id'
      || key === 'user_wix_id'
      || key === 'user_type'
      || key === 'is_new'
      || key === 'qstack'
    ) {
      continue;
    }

    // Check if the value is not null
    if (obj[key] !== null && obj[key] !== undefined && (typeof obj[key] !== 'object' || obj[key].length > 0)) {
      count++;
    }
  }
  return count;
};

export const extractNonNullValuesInfo = obj => {
  const nonNullKeyValuePairs = {}; // Use an object to store key-value pairs directly

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      if (
        Array.isArray(obj[key])
        && obj[key].length > 0
        && key !== 'profile_id'
        && key !== 'temp_id'
        && key !== 'user_wix_id'
        && key !== 'user_type'
        && key !== 'is_new'
        && key !== 'qstack'
      ) {
        nonNullKeyValuePairs[key] = obj[key];
      } else if (!Array.isArray(obj[key])) {
        nonNullKeyValuePairs[key] = obj[key];
      }
    }
  }
  return nonNullKeyValuePairs;
};
