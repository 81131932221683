import app from 'new-ui/app';
import { authenticationActions } from 'components/Authentication/actions';
import { CancerFieldsByIndication } from 'utils/constants/cancerTypes';
import { getSearchParamsApi } from 'new-ui/api';
import { getLoggedUser } from 'new-ui/functions';
import { getQuestionnaireRoute } from 'functions/getQuestionnaireRoute';
import globalEnums from 'components/Questionnaire/constants/globalEnums';
import { indicationChooser } from 'components/Questionnaire/functions';

const SIGNUP_URL = '/?newprofile=true';

export const getUserDataByParams = async (params = {}) => {
  console.log('getUserDataByParams params', params);
  const searchParamsData = app?.cache?.searchParamsData || await getSearchParamsApi();
  const { condition } = params;
  const fields = CancerFieldsByIndication[condition];
  const condition_profile = {};
  const info = {};
  const qstack = [];
  const indicationData = searchParamsData.indicationSearchData.find((ind) => ind.condition === condition);

  condition_profile.qstack = [];

  if (params.country) info.country = params.country;

  info.condition = condition;
  condition_profile.qstack = condition ? indicationChooser(condition) : [];

  if (params.diseaseStatus) {
    qstack.push('disease_status');
    condition_profile.disease_status = params.diseaseStatus;
  }

  if (params.receivedAllogenicTransplant) {
    qstack.push('received_allogenic_transplant');
    condition_profile.received_allogenic_transplant = params.receivedAllogenicTransplant;
  }

  if (params.chromosomalAlterions) {
    qstack.push('chromosomal_alterions');
    condition_profile.chromosomal_alterions = params.chromosomalAlterions;
  }

  Object.keys(params).forEach((param) => {
    if (param === 'country') return;
    if (param === 'diseaseStatus') return;
    if (param === 'biomarkers' && params?.biomarkers?.length) {
      condition_profile.biomarkers = params.biomarkers;
      qstack.push('biomarkers');
    } else {
      let paramValue = params[param];
      if (param === 'diseaseSubType') {
        param = params.diseaseSubType?.subtype;
        paramValue = params.diseaseSubType?.value;
      }
      if (
        indicationData
        && indicationData[param]
        && Array.isArray(indicationData[param])
        && indicationData[param].length
        && fields[param]
      ) {
        const data = indicationData[param].find((q) => q.value === paramValue);
        if (data) {
          condition_profile[fields[param]] = data.value;
          qstack.push(fields[param]);
        }
      }
    }
    condition_profile.qstack = qstack;
  });

  return {
    condition_profile,
    info,
  };
};

export const gotoQuestionnaire = async (params = {}) => {
  if (getLoggedUser()) {
    return app.history.push(getQuestionnaireRoute({ questionId: globalEnums.disease_status }));
  }

  if (!params?.condition) {
    window.location = SIGNUP_URL;
    return null;
  }

  const data = getUserDataByParams(params);

  app.store.dispatch(authenticationActions.setConditionProfile(data.condition_profile));
  app.store.dispatch(authenticationActions.goToQuestionnaire(params.condition, {}));
  return null;
};
