import {
  createFileProcessingJob, getUserProfile, uploadFiles,
} from '../../components/MedicalFiles/api';
import { EXTRACTION_STRATEGY, JOB_STATUS } from '../../components/MedicalFiles/constants';
import { poll } from '../../components/MedicalFiles/utils';

export const uploadNGS = async (
  files = [],
  getRecaptchaTokenAsync,
  onupload,
  onerror,
  extractionStrategy = EXTRACTION_STRATEGY.BIOMARKERS,
) => {
  try {
    const fileKeys = await uploadFiles(files, await getRecaptchaTokenAsync());
    const id = await createFileProcessingJob(fileKeys, extractionStrategy, await getRecaptchaTokenAsync());

    const profile = await poll(
      () => getUserProfile(id),
      (job) => job.status === JOB_STATUS.IN_PROGRESS,
      5000,
    );

    if (onupload) {
      return onupload(profile);
    }

    return profile;
  } catch (e) {
    console.log('upload file error', e);
    if (onerror) {
      onerror(e);
    }
  }
};
