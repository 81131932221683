export const riskGroupIndications = [
  'aml',
  'cll',
  'mds',
  'mpn',
];

export const riskGroupOptions = {
  aml: [
    {
      label: 'questionnaire.questions.aml.risk_group.options.favorable',
      value: 'favorable',
    },
    {
      label: 'questionnaire.questions.aml.risk_group.options.intermediate',
      value: 'intermediate',
    },
    {
      label: 'questionnaire.questions.aml.risk_group.options.poor',
      value: 'poor',
    },
    {
      label: 'questionnaire.questions.aml.risk_group.options.not_sure',
      value: 'not_sure',
    },
  ],
  aml_apl: [
    {
      label: 'questionnaire.questions.aml.risk_group_apl.options.low',
      value: 'low',
    },
    {
      label: 'questionnaire.questions.aml.risk_group_apl.options.high',
      value: 'high',
    },
    {
      label: 'questionnaire.questions.aml.risk_group_apl.options.not_sure',
      value: 'not_sure',
    },
  ],
  cll: [
    {
      label: 'questionnaire.questions.cll.risk_group_ipi.7.options.low',
      value: 'low',
    },
    {
      label: 'questionnaire.questions.cll.risk_group_ipi.7.options.intermediate',
      value: 'intermediate',
    },
    {
      label: 'questionnaire.questions.cll.risk_group_ipi.7.options.high',
      value: 'high',
    },
    {
      label: 'questionnaire.questions.cll.risk_group_ipi.7.options.very_high',
      value: 'very_high',
    },
    {
      label: 'questionnaire.questions.cll.risk_group_ipi.7.options.not_sure',
      value: 'not_sure',
    },
  ],
  mds: [
    {
      label: 'questionnaire.questions.mds.6_risk_group.options.very_low_risk',
      value: 'very_low_risk',
    },
    {
      label: 'questionnaire.questions.mds.6_risk_group.options.low_risk',
      value: 'low_risk',
    },
    {
      label: 'questionnaire.questions.mds.6_risk_group.options.intermediate_risk',
      value: 'intermediate_risk',
    },
    {
      label: 'questionnaire.questions.mds.6_risk_group.options.high_risk',
      value: 'high_risk',
    },
    {
      label: 'questionnaire.questions.mds.6_risk_group.options.very_high_risk',
      value: 'very_high_risk',
    },
  ],
  mpn: [
    {
      label: 'questionnaire.questions.mpn.5_risk_group.options.low_risk',
      value: 'low_risk',
    },
    {
      label: 'questionnaire.questions.mpn.5_risk_group.options.intermediate_risk',
      value: 'intermediate_risk',
    },
    {
      label: 'questionnaire.questions.mpn.5_risk_group.options.high_risk',
      value: 'high_risk',
    },
  ],
};
