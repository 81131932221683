import * as Sentry from '@sentry/browser';
import { authHeaders } from 'functions/authHeader';
import configs from 'configs';
import getUrlWithQueryString from 'functions/getUrlWithQueryString';
import getAccessToken from 'functions/getAccessToken';
import { formatMatchesResultFromApi } from 'functions/formatMatchesResultFromApi';
import request from 'functions/api/request';
import moment from 'moment';
import { objectIterator } from 'new-ui/functions';

const apiUrl = configs.beApiUrl;

const signup = (data, type) => {
  const requestOptions = {
    url: type ? `${apiUrl}/user/signup/${type}` : `${apiUrl}/user/signup`,
    data,
    json: true,
  };

  return request.post(requestOptions);
};

const login = async (data, type) => {
  let response = null;

  const requestOptions = {
    url: type ? `${apiUrl}/user/login/${type}` : `${apiUrl}/user/login`,
    data,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while updating existing user profile');
    return { error: ex.message, ...ex } || null;
  }

  return response;
};

const passwordlessLogin = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/passwordless-login`,
    data,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while updating existing user profile');
    return { error: ex.message } || null;
  }

  return response;
};

const verify = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/verify`,
    data,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error(ex);
    throw ex;
  }

  return response;
};

const forgotPassword = async (email) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/forgot-password`,
    data: email,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error(ex);
    throw ex;
  }

  return response;
};

const resetPassword = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/reset-password`,
    data,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error(ex);
    return ex;
  }

  return response;
};

const createTempUser = async (user) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/temp`,
    data: user,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while creating new user profile:', ex);
    throw ex;
  }

  return response;
};

const createFullUser = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user`,
    headers: await authHeaders(),
    data,
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while creating new user profile for existing user:', ex);
    throw ex;
  }

  return response;
};

const getTrialsMatches = async (data) => {
  const {
    patientId, lat, lng, distance, lang, matchFlow,
  } = data;
  let response = null;

  const url = getUrlWithQueryString(`${apiUrl}/profile/matches`, {
    patientId,
    lat,
    lng,
    distance,
    lang,
    matchFlow,
  });

  const requestOptions = {
    url,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting trials matches:', ex);
    throw ex;
  }

  return formatMatchesResultFromApi(response);
};

const updateUser = async (data) => {
  let response = null;

  objectIterator(data, (v) => {
    if (moment(v, moment.ISO_8601, true).isValid()) {
      return moment(v).format('YYYY-MM-DD');
    }
    return v;
  });

  const requestOptions = {
    url: `${apiUrl}/user`,
    headers: await authHeaders(),
    data,
    json: true,
  };

  try {
    response = await request.put(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while updating existing user profile', ex);
    throw ex;
  }

  return response;
};

const getUser = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/profile`,
    qs: {
      lang: data?.lang || null,
    },
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting user profile', ex);
    throw ex;
  }

  return response;
};

const getProfileData = async (profileId) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/info/${profileId}`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting profile data:', ex);
    throw ex;
  }

  return response;
};

const sendToClinicalTeam = async (token, answersArray, message = null) => {
  if (!token || !Array.isArray(answersArray) || !answersArray.length) {
    console.error('Some parameters are empty:');
    console.error('token [required]:', token);
    console.error('answersArray [required]:', answersArray);
    console.error('message [optional]:', message);
    return;
  }

  let response = null;

  const requestOptions = {
    url: `${apiUrl}/email/sendToClinicalTeam`,
    data: {
      token,
      answers: answersArray,
      message,
    },
    json: true,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while sending request for sending a message to clinical team:', ex);
    throw ex;
  }

  return response;
};

const getTreatmentFinderData = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/statistic`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting profile data:', ex);
    throw ex;
  }

  return response;
};

const getSponsorLocations = async (userWixId, nctId) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/matches/${userWixId}/${nctId}`,
    qs: {
      type: 'facilities',
    },
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    Sentry.captureException(ex);
    console.error('Error occurred, while getting profile data:', ex);
    throw ex;
  }

  return response;
};

const getCrmChatAuthToken = async () => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/crm/chat/token`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting chat token:', ex);
    throw ex;
  }

  return response && response.data;
};

const registerUserRequests = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/crm/requests/register`,
    headers: await authHeaders(),
    json: true,
    data,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, register crm user request:', ex);
    throw ex;
  }

  if (!response?.success) {
    throw new Error('Wrong response from tj-be');
  }

  return response.success;
};

const updateIntercomUser = async (data) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/user/intercom/update`,
    headers: await authHeaders(),
    json: true,
    data,
  };

  try {
    response = await request.post(requestOptions);
  } catch (ex) {
    console.error('Error occurred, update intercom user request:', ex);
    throw ex;
  }

  if (!response?.success) {
    throw new Error('Wrong response from tj-be');
  }

  return response.success;
};

export const authenticationService = {
  signup,
  login,
  passwordlessLogin,
  verify,
  forgotPassword,
  resetPassword,
  createFullUser,
  createTempUser,
  getAccessToken,
  getTreatmentFinderData,
  getProfileData,
  getTrialsMatches,
  getUser,
  sendToClinicalTeam,
  updateUser,
  getSponsorLocations,
  getCrmChatAuthToken,
  registerUserRequests,
  updateIntercomUser,
};
