import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './TreatmentList.css';
import { conditionalClasses } from 'helpers';
import ArrowIcon from 'new-ui/assets/icons/arrow.svg';
import PropTypes from 'prop-types';
import InfoNote from '../InfoNote/InfoNote';

const TreatmentList = ({ treatmentsCategories, renderItems }) => {
  const { t } = useTranslation();
  const [expandedTreatments, setExpandedTreatments] = useState([0]);
  const [closedNotes, setClosedNotes] = useState([]);

  const toggleTreatment = (index) => {
    if (!expandedTreatments.includes(index)) {
      setClosedNotes(closedNotes.filter((i) => i !== index));
    }
    setExpandedTreatments(
      expandedTreatments.includes(index)
        ? expandedTreatments.filter((i) => i !== index)
        : [...expandedTreatments, index],
    );
  };

  const onNoteClose = (index) => {
    setClosedNotes([...closedNotes, index]);
  };

  const getInfoBoxTranslation = (key) => {
    const trans = `match.list.treatment.category.description.${key}`;
    const translated = t(trans);
    if (trans === translated) return '';
    return translated;
  };

  return (
    <div className="treatment-list">
      {treatmentsCategories.map(({ treatment_category_key, treatment_category_description_key, treatments }, index) => (
        <div
          key={index}
          className={conditionalClasses({
            collapsed: !expandedTreatments.includes(index),
          })}
        >
          <div
            id={`item-${index}`}
            onClick={() => toggleTreatment(index)}
            className="treatment-header list-item"
          >
            <span className="arrow"><img src={ArrowIcon} alt="arrow" /></span>
            <p
              className="title"
            >
              {t(`match.list.treatment.category.title.${treatment_category_key}`)}

            </p>
            <span className="index">{treatments.length}</span>
          </div>
          <div className="collapse-container">
            {getInfoBoxTranslation(treatment_category_description_key)
              && (
                <InfoNote
                  closed={closedNotes.includes(index)}
                  onClose={() => onNoteClose(index)}
                  isExpanded
                  info={getInfoBoxTranslation(treatment_category_description_key)}
                />
              )}
            {renderItems(treatments, 0)}
          </div>

        </div>
      ))}
    </div>
  );
};

TreatmentList.propTypes = {
  treatmentsCategories: PropTypes.array,
  renderItems: PropTypes.any,
};

export default TreatmentList;
