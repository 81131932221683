/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import moment from 'moment';
import { getSurvey, setSurvey } from 'components/Surveys/api';
import { PopupContent } from 'new-ui/Components/Popup';
import app from 'new-ui/app';
import Form from 'new-ui/Components/Form';
import './NextScan.css';

const NextScanChat = ({ setShowChatNextScan, setChatHistory }) => {
  const [form, setForm] = useState(false);
  const { t } = useTranslation();
  const { user_wix_id } = app.user.info;
  const SURVEY_NAME = 'nextScan';

  useEffect(() => {}, []);

  const dateFormatter = (date = new Date()) => moment(date).format('MM/DD/YYYY');

  useEffect(() => {
    const fetch = async () => {
      const r = await getSurvey(SURVEY_NAME, user_wix_id);
      const nextScan = r?.data?.answers[0]?.value || '';

      const formData = {
        inputs: {
          nextScan: {
            type: 'date',
            minDate: new Date(),
            default: 'MM/DD/YYYY',
            formatter: dateFormatter,
          },
        },
        data: {
          nextScan,
          notScheduled: null,
        },
      };
      setForm(formData);
      const $chatPopup = document.querySelector('.chat-popup');
      if ($chatPopup) {
        $chatPopup.scrollTop = $chatPopup.scrollHeight;
      }
    };
    fetch();
  }, [user_wix_id]);

  const send = async setShowChatNextScan => {
    const answers = [
      {
        surveyKey: 'general',
        key: 'nextScan',
        value: form.data.nextScan,
      },
    ];
    await setSurvey(SURVEY_NAME, answers, user_wix_id);
    setShowChatNextScan(false);
    setChatHistory(chatHistory => {
      // Create a copy of the chatHistory array
      const updatedChatHistory = [...chatHistory];

      // Remove the last element from the copied array
      updatedChatHistory.pop();

      // Add the new object to the end of the copied array
      updatedChatHistory.push({
        role: 'Jane (patient navigator)',
        content: 'Thank you for sharing with us!',
      });

      // Return the updated array
      return updatedChatHistory;
    });
  };

  const handleRadioChange = (name, newValue) => {
    // Update form data
    form.data[name] = newValue;
    setForm({ ...form }); // Update state to trigger re-render

    // Send data if it's the "nextScan" radio button
    send(setShowChatNextScan);
  };

  return (
    <PopupContent>
      <div className="next-scan-chat">
        {form ? (
          <div>
            <Form
              inputs={form.inputs}
              data={form.data}
              popupMode
              onChange={handleRadioChange} // Pass the custom onChange handler
              submit={{ title: t('share_with_doctor.send'), action: setShowChatNextScan => send(setShowChatNextScan) }}
            />
            <div className="temp" />
          </div>
        ) : null}
      </div>
    </PopupContent>
  );
};

export default NextScanChat;
