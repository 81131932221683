/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import app from 'new-ui/app';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import { push, replace } from 'connected-react-router';
import * as queryString from 'query-string';
import { batch } from 'react-redux';

import { ROUTE_NAME, ROUTE_PATH } from 'components/AppRoutes/constants';
import MAIN_CONSTANTS from 'utils/constants/main';
import { authenticationActions } from 'components/Authentication/actions';
import { matchesActions } from 'components/Matches/actions';
import LANGUAGE from 'components/AppTranslationWrapper/constants/language';
import REDUX_ACTIONS from 'components/Questionnaire/constants/reduxActions';
import globalEnums from 'components/Questionnaire/constants/globalEnums';
import enumsThatSupportOptionsFromServer from 'components/Questionnaire/constants/enumsThatSupportOptionsFromServer';
import SECTIONS from 'components/Questionnaire/constants/questionSections';
import ACTION_TYPES from 'functions/analytics/constants/actionTypes';
import analytics from 'functions/analytics';
import { geoActions } from 'utils/actions/geo';
import { indicationChooser, conditionsChecker, isIndicationFromBackend } from 'components/Questionnaire/functions';
import getFormValue from 'components/Questionnaire/functions/getFormValue';
import { questionnaireService } from 'components/Questionnaire/api';
import { clearDialogData } from 'components/Dialogs/actions';
import { MATCH_FLOW } from 'components/Matches/constants';
import formatMonthPickerValue from 'components/Questionnaire/functions/formatMonthPickerValue';
import { formatOptionsFromServerData } from 'components/Questionnaire/functions/formatOptionsFromServerData';
import { LatestMatchesFlow } from 'domains/Matches/LatesetMatchesFlow';
import { memoize } from 'lodash/function';
import Intercom from 'new-ui/Components/Intercom';
import { hideBackdrop, showBackdrop } from 'modules/backdrop/actions';
import { BACKDROP_TYPES } from 'modules/backdrop/constants';
import sessionStorageKeys from 'utils/constants/sessionStorageKeys';
import LOCAL_STORAGE_KEYS from 'utils/constants/localStorageKeys';
import { getQuestionnaireRoute } from 'functions/getQuestionnaireRoute';
import { GA_QUESTIONNAIRE_CATEGORY, GA_QUESTIONNAIRE_EVENTS } from 'new-ui/Components/GoogleAnalytics/generalEvents';
import { mapFormToConditionProfile } from './functions/mapFormToConditionProfile';
import { mapConditionProfileToForm } from './functions/mapConditionProfileToForm';
import { selectOptionsFromServer } from './selectors';
import {
  setUploadedQuestionnaireFiles,
  addQuestionnaireFiles,
  removeQuestionnaireFiles,
  uploadQuestionnaireFiles,
  resetUploadedQuestionnaireFiles,
} from './filesActions';
import { treatmentForIndicationQuestionIds } from './constants/treatmentForIndicationQuestionIds';

export {
  setUploadedQuestionnaireFiles,
  addQuestionnaireFiles,
  removeQuestionnaireFiles,
  uploadQuestionnaireFiles,
  resetUploadedQuestionnaireFiles,
};

export const setQuestionnaireState = (payload) => ({
  type: REDUX_ACTIONS.QUESTIONNAIRE_STATE_SET,
  payload,
});

export const setQuestionnaireStep = (payload) => ({
  type: REDUX_ACTIONS.QUESTIONNAIRE_STEP,
  payload,
});

export const setIsFetching = (payload) => ({
  type: REDUX_ACTIONS.IS_FETCHING_SET,
  payload,
});

export const setQuestionnaireNavigationDisabled = (payload) => ({
  type: REDUX_ACTIONS.QUESTIONNAIRE_NAVIGATION_DISABLED,
  payload,
});

export const setSidebarVisibility = (payload) => ({
  type: REDUX_ACTIONS.SET_SIDEBAR_VISIBILITY,
  payload,
});

export const setFieldValue = (payload) => ({
  type: REDUX_ACTIONS.FORM_FIELD_VALUE_SET,
  payload: {
    id: payload.id,
    value: payload.value,
  },
});

export const setSkipStep = () => ({
  type: REDUX_ACTIONS.QUESTIONNAIRE_SKIP_STEP,
});

export const setIsLastQuestion = {
  type: REDUX_ACTIONS.IS_LAST_QUESTION,
};

export const setIsNotLastQuestion = {
  type: REDUX_ACTIONS.IS_NOT_LAST_QUESTION,
};

export const fetchQuestionOptions = (onComplete, condition) => async (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.GET_QUESTION_OPTIONS_REQUEST,
  });
  const payload = {};
  try {
    await Promise.all(Object.values(enumsThatSupportOptionsFromServer).map(async (questionId) => {
      const result = await questionnaireService.getQuestionOptions(questionId, condition);
      if (!result.data) {
        throw new Error('no data in response');
      }
      payload[questionId] = await formatOptionsFromServerData(result.data, questionId);
      payload[questionId].responseData = result.data;
    }));
  } catch (ex) {
    console.error('Error occurred, while getting question options from server:', ex);

    batch(() => {
      dispatch({
        type: REDUX_ACTIONS.GET_QUESTION_OPTIONS_FAILURE,
        payload: ex,
      });
      dispatch(setIsFetching(false));
    });
    return;
  }

  dispatch({
    type: REDUX_ACTIONS.GET_QUESTION_OPTIONS_SUCCESS,
    payload,
  });

  if (typeof onComplete === 'function') {
    onComplete();
  }
  dispatch(setIsFetching(false));
};

export const makeMatchesRequest = () => async (dispatch, getState) => {
  const state = getState();
  const { authentication, profile } = state;
  await updateUser(authentication.loggedIn, dispatch, state);

  await dispatch(matchesActions.getTrialsMatches({
    patientId: profile.personal.patient_id,
    lat: profile.info?.country?.location?.lat ?? null,
    lng: profile.info?.country?.location?.lng ?? null,
    distance: profile.personal.travel_limit,
    lang: localStorage.getItem(LOCAL_STORAGE_KEYS.LANG) || '',
    matchFlow: MATCH_FLOW.BASIC,
  }));
};

export const goToFastSignUp = (shouldShowQuickSignupPopup = false) => (dispatch, getState) => {
  const state = getState();
  const {
    profile,
  } = state;
  analytics.quickSignUpStarted({ questionId: profile?.personal?.last_updated_question });
  dispatch(setIsFetching(true));

  const _condition = profile?.personal?.condition;
  const _event = !app.isIntakeFlow(_condition)
    ? `${GA_QUESTIONNAIRE_EVENTS.QUICK_SIGNUP_POPUP_VIEW}_${profile?.personal?.condition}`
    : `${GA_QUESTIONNAIRE_EVENTS.QUICK_SIGNUP_POPUP_VIEW_INTAKE}_${profile?.personal?.condition}`;
  app.sendGoogleAnalyticsEvent(GA_QUESTIONNAIRE_CATEGORY, _event);

  dispatch(authenticationActions.startUserRegistration({
    profileData: profile,
    quickSignup: true,
    isModalSignup: shouldShowQuickSignupPopup,
  }));
};

// reformat optionsFromServer in the case ui language is changed
export const reformatOptionsFromServer = async (dispatch, getState) => {
  const optionsFromServer = selectOptionsFromServer(getState());

  if (!optionsFromServer) {
    return;
  }

  try {
    const payload = { ...optionsFromServer };
    await Promise.all(Object.values(enumsThatSupportOptionsFromServer).map(async (questionId) => {
      if (!optionsFromServer[questionId]) {
        return;
      }
      // take preserved response data
      const responseData = optionsFromServer[questionId]?.responseData;
      if (!responseData) {
        // invariant
        payload[questionId] = optionsFromServer[questionId];
        return;
      }
      payload[questionId] = await formatOptionsFromServerData(responseData, questionId);
      payload[questionId].responseData = responseData;
    }));
    dispatch({
      type: REDUX_ACTIONS.GET_QUESTION_OPTIONS_SUCCESS,
      payload,
    });
  } catch (error) {
    console.error('reformatOptionsFromServer action error', error);
  }
};

export const findIndexOfQuestionInQstack = (questionId, qstack) => (
  findIndex(qstack, (question) => question.id === questionId)
);

export const fetchDiseases = () => async (dispatch) => {
  const data = await questionnaireService.getDiseasesList();
  dispatch({ type: REDUX_ACTIONS.GET_ACTIVE_DISEASE, payload: [...data] });
};

// todo add questionnaire to store
export const fetchQuestionnaireData = memoize(async (diseaseName) => questionnaireService.getQuestionnaireStructure(diseaseName));

export const initQuestionnaire = (condition, match) => async (dispatch, getState) => {
  const state = getState();
  const {
    profile, questionnaire, authentication, main,
  } = state;
  const { optionsFromServer, isFetchingOptionsFromServer } = questionnaire;

  if (
    // eslint-disable-next-line
    Object.values(enumsThatSupportOptionsFromServer).length !== Object.values(optionsFromServer).filter((i) => !!i).length
    && !isFetchingOptionsFromServer
  ) {
    // This will fetch the biomarker and drug received options
    const onGottenOptionsFromServer = () => {
      dispatch(initQuestionnaire(condition, match));
    };

    dispatch(fetchQuestionOptions(onGottenOptionsFromServer, profile?.personal?.condition));

    return;
  }

  const allQuestions = await indicationChooser(condition);

  analytics.userIsMovedToTheNextSection({
    currentSection: null,
    nextSection: (allQuestions || [])[0].section || SECTIONS.ABOUT_ME,
    actionType: ACTION_TYPES.INDICATION_CHOSEN,
  });

  dispatch(setQuestionnaireState({ allQuestions }));

  const { questionId } = match.params;
  const form = mapConditionProfileToForm({
    allQuestions: await indicationChooser(profile?.personal?.condition),
    qstack: profile?.condition_profile?.qstack ?? [],
    conditionProfile: {
      ...(profile?.condition_profile || {}),
      ...(profile?.personal || {}),
      ...(profile?.info || {}),
    },
    optionsFromServer: questionnaire?.optionsFromServer,
    condition: profile?.personal?.condition,
    dispatch,
  });

  const loggedIn = !!(authentication && authentication.loggedIn && authentication.user);

  if (!loggedIn) {
    const _condition = profile?.personal?.condition;
    const _event = !app.isIntakeFlow(_condition)
      ? `${GA_QUESTIONNAIRE_EVENTS.QUESTIONNAIRE_STARTED}_${profile?.personal?.condition}`
      : `${GA_QUESTIONNAIRE_EVENTS.QUESTIONNAIRE_STARTED_INTAKE}_${profile?.personal?.condition}`;

    app.sendGoogleAnalyticsEvent(GA_QUESTIONNAIRE_CATEGORY, _event);
  }

  let currentIndex;

  // Must update `qstack` before continuing
  const qstack = updateQStack({
    allQuestions,
    form,
    dispatch,
    profile,
    state,
  });

  if (main.lang === LANGUAGE.he.SHORT) {
    const isrealLocation = {
      location: {
        lat: 31.76318669999999,
        lng: 35.2056087,
      },
      label: 'Israel Aharoni Street, Jerusalem, Israel',
      name: 'Israel',
    };
    dispatch(setGeoLocation(isrealLocation));

    form.travel_limit = '200';
  }

  // If user is editing of existing profile or user has answered "user_type" question
  // we shouldn't show him the "user_type" question
  if (
    loggedIn
    && (!!authentication.user.user_type || !!profile.info.user_type || !!profile.condition_profile.user_type)
  ) {
    if (!questionId) {
      currentIndex = 1;
    } else {
      currentIndex = findIndexOfQuestionInQstack(questionId, qstack);

      if (currentIndex < 1) {
        currentIndex = 0;
      }
    }
  } else if (!questionId) {
    currentIndex = 0;
  } else {
    currentIndex = findIndexOfQuestionInQstack(questionId, qstack);

    if (currentIndex < 0) {
      currentIndex = 0;
    }
  }
  batch(() => {
    dispatch(setQuestionnaireState({
      currentIndex, submitted: false, form, showIntro: true,
    }));
    dispatch(setUploadedQuestionnaireFiles(profile?.personal?.documents_data ?? []));
    dispatch(setIsFetching(false));
  });
};

export const goToQuestion = ({ currentIndex, prevPathname }) => (dispatch, getState) => {
  const { dialog } = getState();

  if (dialog?.open && prevPathname) {
    dispatch(clearDialogData());
    dispatch(push(prevPathname));
  } else {
    dispatch(setQuestionnaireState({ currentIndex, submitted: false }));
  }
};

export const infoAttributes = [
  'given_name',
  'first_name',
  'family_name',
  'last_name',
  'phone',
  'last_location',
  'user_role',
  'registered_at',
  'last_modified_at',
  'email',
  'preferred_location',
  'country',
  'partner',
  'gender',
  'dob',
  'user_type',
  'looking_for',
];

const updateUser = async (isDone = null, dispatch, state) => {
  const {
    authentication, questionnaire, profile, router,
  } = state;
  const { personal, prevLastUpdatedQuestion } = profile;
  const loggedIn = !!(authentication && authentication.loggedIn && authentication.user);
  const { form, qstack, allQuestions } = questionnaire;
  delete state.profile.prevLastUpdatedQuestion;
  const requiredQuestion = personal.hasOwnProperty('reached_required_question')
    ? Boolean(personal.reached_required_question)
    : false;

  const isReachedRequiredQuestion = requiredQuestion
    || JSON.parse(sessionStorage.getItem(sessionStorageKeys.REACHED_REQUIRED_QUESTION) || false);

  const geoLocation = state.geo_location;
  const { location } = router;

  const user = (loggedIn && authentication.user) || {};
  const toUpdate = {
    info: {
      user_wix_id: user.user_wix_id || null,
      country: null,
      email: user.email || null,
      family_name: form.family_name || null,
      phone: user.phone || null,
      user_type:
        user.user_type || profile.info.user_type || profile.condition_profile.user_type || getFormValue(form.user_type),
    },
    personal: {
      user_wix_id: user.user_wix_id || null,
      profile_id: user.user_wix_id ? user.profile_id || profile.personal.profile_id || null : null,
      patient_id: user.user_wix_id ? user.patient_id || profile.personal.patient_id || null : null,
      condition: profile.personal.condition,
      gender: getFormValue(form.gender),
      dob: formatMonthPickerValue(form.dob)?.format('YYYY-MM-DD') || null,
      is_done: isDone ? 'Y' : 'N',
      travel_limit: getFormValue(form.travel_limit),
      looking_for: getFormValue(form.looking_for),
      last_updated_question: isDone ? null : profile.personal.last_updated_question,
      reached_required_question: isDone ? true : isReachedRequiredQuestion,
    },
    condition_profile: {},
  };

  const currentPage = location.pathname.replace('/questionnaire/', '');
  const isInfoAttribute = infoAttributes.includes(currentPage);

  let conditionProfile;
  if (isIndicationFromBackend(profile.personal.condition)) {
    conditionProfile = {
      [globalEnums.tnm]: [],
      [globalEnums.treatments.questionId]: [],
      [globalEnums.procedures.questionId]: [],
      ...mapFormToConditionProfile(form, allQuestions),
      qstack: profile && isInfoAttribute ? profile.condition_profile.qstack : qstack.map((q) => q.id),
    };

    for (const attribute in conditionProfile) {
      if (attribute === 'preferred_location') {
        delete conditionProfile[attribute];
      }
    }
  } else {
    conditionProfile = {
      [globalEnums.treatments.questionId]: [],
      [globalEnums.procedures.questionId]: [],
      ...mapFormToConditionProfile(form, allQuestions),
      qstack: profile && isInfoAttribute ? profile.condition_profile.qstack : qstack.map((q) => q.id),
    };

    // Removes personal / info attributes from the condition_profile
    for (const attribute in conditionProfile) {
      if (attribute in toUpdate.personal || infoAttributes.includes(attribute)) {
        if (![globalEnums.dob, globalEnums.gender].includes(attribute)) {
          delete conditionProfile[attribute];
        }
      }
    }
  }

  if (!isEmpty(geoLocation)) {
    toUpdate.info = {
      ...toUpdate.info,
      country: geoLocation,
    };
  }

  if (conditionProfile.treatments) {
    conditionProfile.treatments = conditionProfile.treatments.sort(
      (a, b) => ((b.date_of_adding ?? 0) - (a.date_of_adding ?? 0)),
    );
  }

  toUpdate.condition_profile = conditionProfile;

  if (
    !isEqual(profile.condition_profile, toUpdate.condition_profile)
    || !isEqual(profile.personal, toUpdate.personal)
  ) {
    await dispatch(authenticationActions.updateUser(toUpdate, loggedIn, profile));
  }

  if (isDone && prevLastUpdatedQuestion) {
    Intercom.updateUserAttributes({ quick_signup: false, email: user.email }, profile.personal.user_wix_id);
  }
  return toUpdate;
};

export const skipStep = () => (dispatch, getState) => {
  let prevPIndex;
  const {
    form, currentIndex, qstack, pstack,
  } = getState().questionnaire;
  if (!qstack[currentIndex]) {
    return;
  }

  const { id } = qstack[currentIndex];

  if (form[id] === undefined) {
    dispatch(
      setFieldValue({
        id,
        value: null,
      }),
    );
  }

  dispatch(nextStep(true));
  if (pstack) {
    prevPIndex = pstack.findIndex((el) => el === qstack[currentIndex].id);
  }
  if (qstack.length === currentIndex + 1 || (pstack && pstack.length === prevPIndex + 1)) {
    dispatch(setIsLastQuestion);
  }
};

export const backStep = () => (dispatch, getState) => {
  dispatch(setQuestionnaireStep('back'));
  const { authentication, profile, questionnaire } = getState();
  const loggedIn = !!(authentication && authentication.loggedIn && authentication.user);

  const { currentIndex, qstack, pstack } = questionnaire;
  let backStepIndex;
  let shouldGoBack;
  let prevPIndex = 0;
  if (pstack) {
    const pIndex = pstack.findIndex((el) => el === qstack[currentIndex].id);
    prevPIndex = pIndex - 1;
    backStepIndex = qstack.findIndex((el) => el.id === pstack[prevPIndex]);
    shouldGoBack = pIndex > 0;
  } else {
    shouldGoBack = currentIndex > 0;
    backStepIndex = currentIndex - 1;
  }
  if (prevPIndex === -1) {
    return;
  }

  if (shouldGoBack) {
    // If user is editing of existing profile or user has answered "user_type" question
    // we shouldn't show him the "user_type" question
    if (
      backStepIndex < 1
      && loggedIn
      && (!!authentication.user.user_type || !!profile.info.user_type || !!profile.condition_profile.user_type)
    ) {
      backStepIndex = 1;
    }

    if (currentIndex !== backStepIndex) {
      analytics.userIsMovedToTheNextSection({
        currentSection: qstack[currentIndex].section,
        nextSection: qstack[backStepIndex].section,
        actionType: ACTION_TYPES.STEP_BACK,
      });
      const questionId = qstack[backStepIndex].path || qstack[backStepIndex].id;
      dispatch(push(getQuestionnaireRoute({ questionId })));
    }

    dispatch(
      setQuestionnaireState({
        currentIndex: backStepIndex,
      }),
    );
  }
};

export const updateQStack = ({
  allQuestions, form = {}, dispatch = () => { }, state,
}) => {
  const conditionProfile = mapFormToConditionProfile(form, allQuestions);

  const qstack = [];
  const qstackHidden = [];

  allQuestions.forEach((_question, index) => {
    const question = { ..._question };
    const shouldQuestionBeShown = conditionsChecker(conditionProfile, _question.conditions, state);
    const nextQuestionIndex = state ? state.questionnaire.currentIndex + 1 : 1;
    if (!shouldQuestionBeShown) {
      // if (question.id === 'treatments') {
      //   qstackHidden.push(...question?.subQuestionsIds ?? []);
      // } else {
      qstackHidden.push(question.id);
      // }
      return;
    }
    if (nextQuestionIndex === index && Array.isArray(question?.options)) {
      question.options = question?.options?.filter((option) => conditionsChecker({}, option.conditions, state));
    }
    qstack.push(question);
  });
  batch(() => {
    qstackHidden.forEach((question) => {
      dispatch(
        setFieldValue({
          id: question,
          value: null,
        }),
      );
    });
  });

  dispatch(setQuestionnaireState({ qstack }));

  return qstack;
};

window.updateQstack = updateQStack;

const setGeoLocation = (value) => (dispatch) => {
  dispatch(geoActions.setGeoLocation(value));

  dispatch(
    setFieldValue({
      id: globalEnums.preferred_location,
      value,
    }),
  );
};

export const nextStep = (isSkip = false) => async (dispatch, getState) => {
  let state = getState();
  const {
    questionnaire, profile, main, authentication,
  } = state;
  if (questionnaire.isSidebarOpen) {
    dispatch(setSidebarVisibility(false));
    return;
  }
  dispatch(setQuestionnaireStep('next'));

  const { redirectPathOnceQuestionnaireCompleted } = main;
  const loggedIn = !!(authentication?.loggedIn && authentication?.user?.user_wix_id);
  const { redirectPath, inline } = queryString.parse(window.location.search);

  const { currentIndex: prevQIndex, pstack, updateBetweenQuestions } = questionnaire;

  const qstack = updateQStack({
    allQuestions: questionnaire.allQuestions,
    form: questionnaire.form,
    dispatch,
    profile,
    state,
  });

  state = getState();
  let nextQIndex = prevQIndex + 1;
  let prevPIndex;
  if (prevQIndex >= 1 && (!profile?.info?.user_wix_id
    || (profile?.info?.user_wix_id && profile?.personal?.last_updated_question))) {
    state.profile.prevLastUpdatedQuestion = profile.personal.last_updated_question;
    state.profile.personal.last_updated_question = qstack[nextQIndex]?.id || null;
  }

  if (pstack) {
    prevPIndex = pstack.findIndex((el) => el === qstack[prevQIndex].id);
    nextQIndex = qstack.findIndex((el) => el.id === pstack[prevPIndex + 1]);
  }

  if (qstack.length === nextQIndex || (pstack && pstack.length === prevPIndex + 1)) {
    dispatch(setIsLastQuestion);
  }

  if (!qstack[prevQIndex]) {
    return;
  }

  if (!isSkip) {
    analytics.clickNextOnAQuestion({
      questionId: qstack[prevQIndex].id,
    });
  }

  const isPreferredLocationQuestion = qstack[prevQIndex].id === globalEnums.preferred_location;

  const treatmentForIndicationQuestionId = treatmentForIndicationQuestionIds[profile.personal.condition];
  const treatmentForIndicationQuestion = profile.condition_profile[treatmentForIndicationQuestionId];
  if (!profile.personal.answered_no_on_has_treatment_date && treatmentForIndicationQuestion === 'no') {
    profile.personal.answered_no_on_has_treatment_date = Date.now();
  } else if (treatmentForIndicationQuestion !== 'no') {
    profile.personal.answered_no_on_has_treatment_date = null;
  }

  const isNotLastStep = pstack ? prevPIndex + 1 < pstack.length : nextQIndex < qstack.length;
  let updatedData = null;
  if (isPreferredLocationQuestion && updateBetweenQuestions) {
    const { geo_location: geoLocation } = state;
    if (geoLocation?.label) {
      try {
        updatedData = await updateUser(false, dispatch, state);
      } catch (error) {
        console.error(error);
      }
    } else if (profile && profile.info && (!profile.info.country || !profile.info.country.location)) {
      // checks if preferredLocation was answered
      let userLocation;
      try {
        userLocation = await authenticationActions.getPosition();
        const locationData = {
          label: 'user location',
          location: {
            lat: userLocation.coords.latitude,
            lng: userLocation.coords.longitude,
          },
        };
        dispatch(setGeoLocation(locationData));
      } catch (e) {
        // console.error("Can't get user browser location", e);
      }

      try {
        updatedData = await updateUser(!userLocation, dispatch, state);
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        updatedData = await updateUser(true, dispatch, state);
      } catch (error) {
        console.error(error);
      }
    }
  } else {
    if (!updateBetweenQuestions && !isNotLastStep) {
      try {
        updatedData = await updateUser(!isNotLastStep, dispatch, state);
      } catch (error) {
        console.error(error);
      }
    }
    if (updateBetweenQuestions) {
      try {
        updatedData = await updateUser(!isNotLastStep, dispatch, state);
      } catch (error) {
        console.error(error);
      }
    }
  }

  if (isNotLastStep) {
    if (window.setData) return window.setData(updatedData);

    let nextStepIndex;

    if (pstack) {
      nextStepIndex = nextQIndex;
    } else {
      nextStepIndex = prevQIndex + 1;
    }

    batch(() => {
      if (redirectPath && !questionnaire.isSidebarOpen) {
        // redirect after question answer
        dispatch(replace(redirectPath));
      } else {
        const questionId = qstack[nextStepIndex].path || qstack[nextStepIndex].id;
        const path = getQuestionnaireRoute({ questionId });
        dispatch(push(path));
        dispatch(
          setQuestionnaireState({
            currentIndex: nextStepIndex,
          }),
        );
      }
    });

    analytics.userIsMovedToTheNextSection({
      currentSection: qstack[prevQIndex].section,
      nextSection: qstack[nextStepIndex].section,
      actionType: !isSkip ? ACTION_TYPES.STEP_NEXT : ACTION_TYPES.STEP_SKIP,
    });
  } else if (!questionnaire.submitted) {
    // In case it's last step:
    if (redirectPath) {
      // redirect after question answer
      dispatch(replace(redirectPath));
      return;
    }
    if (inline) {
      if (window.setData) return window.setData(updatedData);
    }

    LatestMatchesFlow.onQuestionnairePass();

    if (loggedIn) {
      // authenticated user
      if (state.profile?.personal?.last_updated_question) {
        dispatch(showBackdrop({ type: BACKDROP_TYPES.SPLASH }));
      }

      const newUser = await dispatch(await authenticationActions.getUser());

      let matchFlow = MATCH_FLOW.LATEST;

      if (newUser.personal.profile_updated_at
        && newUser.personal.profile_created_at) {
        const MS_AGO = 1000 * 60 * 60; // 1 hour
        const MONTH_MILIS = 2.628e+9;
        const TZ_OFFSET = new Date().getTimezoneOffset() * 1000 * 60;

        const updatedAt = new Date(new Date(newUser.personal.profile_updated_at).getTime() - TZ_OFFSET);
        const createdAt = new Date(new Date(newUser.personal.profile_created_at).getTime() - TZ_OFFSET);
        const timeAgo = new Date(new Date().getTime() - MS_AGO);
        const monthAgo = new Date(new Date().getTime() - MONTH_MILIS);

        if (updatedAt >= timeAgo || createdAt >= monthAgo) {
          matchFlow = MATCH_FLOW.BASIC;
        }
      }
      let resultsPath = `${ROUTE_PATH[ROUTE_NAME.RESULTS]}`;
      if (matchFlow === MATCH_FLOW.BASIC) {
        resultsPath += '?rematch=1';
      }

      const finishLastStep = () => {
        batch(() => {
          dispatch(push(redirectPathOnceQuestionnaireCompleted ?? resultsPath)); // ?toSubmit=1
          dispatch({ type: MAIN_CONSTANTS.SET_REDIRECT_PATH_ONCE_QUESTIONNAIRE_COMPLETED, payload: null });
          dispatch({ type: MAIN_CONSTANTS.SET_IS_NEW_PROFILE, payload: false });
        });
      };

      if (state.profile?.personal?.last_updated_question) {
        dispatch(hideBackdrop({
          minDisplayTime: 5000,
          callback: finishLastStep,
        }));
      } else {
        finishLastStep();
      }
    } else {
      dispatch(authenticationActions.startUserRegistration({ profileData: updatedData }));
    }

    dispatch(
      setQuestionnaireState({
        submitted: true,
      }),
    );
  }

  if (qstack.length === nextQIndex || (pstack && pstack.length === prevPIndex + 1)) {
    dispatch(setIsLastQuestion);
  }
};
