import React, { useEffect } from 'react';
import { DOM } from 'new-ui/functions';

import './Playground.css';
import { Accordion } from 'new-ui/Components/Accordion';

import { render } from 'react-dom';

const Playground = () => {
  // const a = 1;
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = '/assets/providers/leal-iframe.js';
  //   document.head.appendChild(script);
  //   const $playground = document.querySelector('.playground');
  //   const $iframe = document.createElement('iframe');
  //   $iframe.setAttribute('id', 'trialjectory-iframe');
  //   $iframe.src = 'https://stage-app.leal.health/?provider=pfizer&condition=breast_2';
  //   $iframe.height = 500;
  //   $iframe.style.width = '100%';
  //   $playground.appendChild($iframe);
  // }, []);

  useEffect(()=>{
    let accordion;

    const head = (item, $el)=>{
      const $ = DOM('.intake-head');
      render(
        <div onClick={item.toggle}>
          top head
          {' '}
          {item.count || 0}
          {' '}
          {item.title}
        </div>,
        $,
      );
      $el.appendChild($);
    };

    const subHead = (item, $el)=>{
      const $ = DOM('.question-head', {
        innerHTML: `sub head ${item.title}`,
        onclick: item.toggle,
      });
      $el.appendChild($);
    };

    const subBody = (item, $el)=>{
      const $ = DOM('.question-body', {
        innerHTML: `question body ${item.title}`,
        onclick: ()=>{
          // accordion.items[0].hidden = !accordion.items[0].hidden;
          accordion.items[0].count++;
          accordion.render();
        },
      });
      $el.appendChild($);
    };

    const items = [
      {
        id: 'a',
        title: 'a section',
        type: 'a',
        head,
        childHead: subHead,
        childBody: subBody,
        count: 1,
        items: [
          {
            id: 'aq1',
            title: 'q1 question',
          },
          {
            id: 'aq2',
            title: 'q2 question',
          },
        ],
      },
      {
        id: 'b',
        title: 'b section',
        head,
        childHead: subHead,
        childBody: subBody,
        type: 'b',
        items: [
          {
            id: 'bq1',
            title: 'q1 question',
          },
          {
            id: 'bq2',
            title: 'q2 question',
          },
        ],
      },
    ];

    const onbeforerender = (accordion)=>{
      console.log('render', accordion);
    };

    accordion = new Accordion({
      $element: document.querySelector('.playground'),
      onbeforerender,
      items,
    });

    window.a = accordion;
  }, []);

  return (
    <div className="playground" />
  );
};

export default Playground;
