import React from 'react';
import './AboutLeal.css';
import { useTranslation } from 'utils/modifiedTranslation';
import WomensImage from '../../assets/womens.svg';

const AboutLeal = () => {
  const { t } = useTranslation();

  return (
    <div className="about-leal">
      <div className="about-leal-top">
        <div className="about-leal-text">{t('upload.about_leal_text')}</div>
      </div>
      <div className="about-leal-wrapper">
        <div>
          <div className="title">{t('upload.about_leal_title_text')}</div>
          <div className="content">{t('upload.about_leal_content_text')}</div>
        </div>
        <div className="wommens-section"><img src={WomensImage} alt="womens" /></div>
      </div>
    </div>
  );
};

export default AboutLeal;
