import React from 'react';
import PropTypes from 'prop-types';
import * as ReactRouterDom from 'react-router-dom';
import * as ReactRouter from 'connected-react-router';
import * as ReactRedux from 'react-redux';

import app from 'new-ui/app';
import useForceUpdate from 'use-force-update';
import { getLoggedUser } from 'new-ui/functions';
import { getQuestionnaireRoute } from 'functions/getQuestionnaireRoute';

const PrivateRoute = (p) => {
  const dispatch = ReactRedux.useDispatch();
  const { loggedIn, component: Component, ...rest } = p;
  const {
    profile,
  } = ReactRedux.useSelector((state) => ({
    profile: state?.profile,
  })) ?? {};

  const { location } = window;

  const lastUpdatedQuestion = profile?.personal?.last_updated_question;
  const condition = profile?.personal?.condition;
  if (lastUpdatedQuestion) {
    if (!app.isIntakeFlow(condition)) dispatch(ReactRouter.push(getQuestionnaireRoute({ questionId: lastUpdatedQuestion, popupType: 'quick_login' })));
  }

  app.renderPage = useForceUpdate();
  const params = new URLSearchParams(window.location.search);
  if (rest?.redirect) window.location = `${rest.redirect}?${params.toString()}`;
  if (!loggedIn && !getLoggedUser()) {
    app.token = params.get('token');
    params.delete('token');
    localStorage.clear();
    const url = `/login?redirect=${location.pathname}?${params.toString()}${app.token ? `&token=${app.token}` : ''}`;
    if (location.pathname.startsWith('/dashboard/trial/')) {
      return window.location = `/trial/${location.pathname.split('/dashboard/trial/')[1]}`;
    }
    window.location = url;
    return null;
  }
  if (!app.user) return null;
  return (
    <ReactRouterDom.Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );
};

const mapStateToProps = (state) => ({
  loggedIn: !!(state.authentication && state.authentication.loggedIn && state.authentication.user),
});

PrivateRoute.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default ReactRouterDom.withRouter(ReactRedux.connect(mapStateToProps)(PrivateRoute));
