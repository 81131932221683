import React from 'react';
import './Steps.css';
import { useTranslation } from 'utils/modifiedTranslation';

const Steps = () => {
  const { t } = useTranslation();

  const uploadSteps = [
    { number: '01', title: t('upload.step1.title'), subtitle: t('upload.step1.subtitle') },
    { number: '02', subtitle: t('upload.step2.subtitle') },
    { number: '03', subtitle: t('upload.step3.subtitle') },
  ];

  return (
    <div className="upload-steps">
      {uploadSteps.map((step, key)=>{
        return (
          <div key={key} className="upload-steps-wrapper">
            <div className="upload-step">{step.number}</div>
            <div>
              {step.title ? <div className="upload-step-title">{step.title}</div> : null}
              <div className="upload-step-subtitle">{step.subtitle}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Steps;
