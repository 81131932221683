/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Overlay from 'new-ui/Components/Overlay';
import { useTranslation } from 'utils/modifiedTranslation';
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import Button from 'new-ui/Components/Button';
import Preloader from 'new-ui/Components/Preloader';
import { getInitialConditionProfileByIndication, signup } from 'new-ui/functions';
import { getIndications } from 'new-ui/api';
import ArrowCirlce from 'new-ui/assets/icons/arrow-circle-right.svg';
import useRecaptcha from 'utils/hooks/useRecaptcha';
// import { STORAGE_SEARCH_BIOMARKERS, STORAGE_SEARCH_INDICATION } from 'new-ui/Search/SearchParameters';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import app from 'new-ui/app';
import { INTERCOM_EVENTS } from 'new-ui/constants';
import lealHealthIcon from 'new-ui/assets/icons/leal_logo_2.svg';
import IsoImage from '../../../../components/Authentication/components/Signup/assets/image/iso.svg';
import HipaaImage from '../../../../components/Authentication/components/Signup/assets/image/hipaa.svg';
import FreeImage from '../../../../components/Authentication/components/Signup/assets/image/free.svg';
import { authenticationActions } from '../../../../components/Authentication/actions';
import './styles.css';

let aIndication = 'breast_2';
export const EmailSignupForm = ({
  showForm, setShowForm, biomarkers, user, setNewUser,
}) => {
  const [formData, setFormData] = useState({ email: '', indication: '', phone: '' });
  const [signupError, setSignupError] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const history = useHistory();
  const { t } = useTranslation();
  const [formDefinition, setFormDefinition] = useState(null);

  const GA = {
    category: 'Upload',
    events: {
      pageview: 'Upload file landing page viewed',
      browse: 'Clicking on browse',
      dragFile: 'Drag a file',
      sendRecord: 'Clicking on Send record by mail',
      answerManually: 'Clickin on Answer manually',
      emailToLater: 'Upload file clicking continue later',
      emailToLaterForm: 'Continue later form viewed',
      emailToLaterPopup: 'Continue later final popup viewed',
      moreUpload: 'Upload more clicked',
      next: 'Clicking on “Next”',
      startVideo: 'Upload file video started',
      endVideo: 'Upload file video finished',
      fileSuccessfully: 'Upload file extracted successfully',
      fileFailed: 'Upload file extracted failed',
      reviewing: 'When the revireing page is presented',
      confirmaed: 'When confirmaed',
      success: 'signup_success',
      error: 'signup_error',
    },
  };

  const handleForm = async () => {
    const { email, indication, phone } = formData;
    setFormLoading(true);
    setSignupError(false);

    try {
      const condition_profile = await getInitialConditionProfileByIndication(indication, {
        biomarkers,
      });

      await signup({
        email,
        indication,
        phone,
        recaptchaToken: await getRecaptchaTokenAsync(),
        condition_profile,
        onsignup: async (newUser) => {
          user = newUser;
          setNewUser(newUser);
          app.user = user;
          app.intercom.updateUserAttributes(
            {
              email: formData.email,
              condition: formData.indication,
              phone: formData.phone,
            },
            newUser.info.user_wix_id,
          );
          app.intercom.sendEvent(INTERCOM_EVENTS.UPLOAD_PAGE.USER_SIGNUP);
          app.sendGoogleAnalyticsEvent(GA.category, GA.events.success, { name: 'user_signup_success' });
          await app.store.dispatch(authenticationActions.updateUser(newUser, true));
          setFormLoading(false);
          setShowForm(false);
          history.push('/ai_profile');
        },
        onerror: (e) => {
          console.error('user signup error', e);
          setFormLoading(false);
          setSignupError(true);
          app.sendGoogleAnalyticsEvent('NGS', 'general', { name: 'user_signup_error' });
        },
      });
    } catch (error) {
      console.error('An error occurred during signup:', error);
      setFormLoading(false);
      setSignupError(true);
    }
  };

  useEffect(() => {
    const fetchIndications = async () => {
      try {
        const indications = await getIndications();
        setFormDefinition({
          inputs: {
            email: {
              type: FORM_INPUT_TYPES.INPUT,
              required: true,
              icon: 'email',
              title: 'Email',
              placeholder: '',
            },
            phone: {
              type: FORM_INPUT_TYPES.PHONE,
              icon: 'phone',
              title: `${t('upload.registration_phone_text')}`,
              placeholder: '651465',
              className: 'registration-phone',
            },
            indication: {
              title: 'Select type',
              placeholder: 'Select type',
              required: true,
              type: FORM_INPUT_TYPES.SELECT,
              autocomplete: true,
              options: indications.map((i) => ({
                title: i.title.indexOf('disease_name') !== -1 ? `${t(i.title)}` : `${t(i.title)}`,
                value: (i.value),
              })),
            },
          },
          data: {
            email: '',
            indication: '',
            phone: '',
          },
        });
      } catch (error) {
        console.error('Error fetching indications:', error);
      }
    };

    fetchIndications();
  }, []);

  return (
    !showForm && (
      <>
        <div>
          <img
            alt="arrow button"
            src={lealHealthIcon}
            style={{
            }}
          />
        </div>
        <Overlay className="ngs-overlay" onclose={() => history.push('/ai_profile')} hideCloseButton>
          <div className="ngs-form-page">
            {recaptcha}
            <div className="center-headtitle">
              <div className="email-signup-center-headtitle-div" />
              <div className="ngs-subtitle">Enter email to keep your medical file private</div>
            </div>
            {formDefinition && (
              <div className="ngs-form">
                <Form
                  form={{
                    ...formDefinition,
                    data: {
                      ...formData,
                    },
                  }}
                  onChange={(data) => {
                    aIndication = data.indication;
                    setFormData({ ...data, indication: aIndication });
                  }}
                />
              </div>
            )}
            {signupError && <div className="ngs-form-error">Oops, Registration error. Please contact support</div>}
            <div className="ngs-footer ngs-form-footer">
              {formLoading && (
                <div className="ngs-form-preloader">
                  <Preloader inline isVisible />
                </div>
              )}
              <Button disabled={formLoading} title="Get Results" forwardIcon={ArrowCirlce} action={handleForm} />
              <div className="upload-page-start-section-bottom">
                <img src={FreeImage} alt="free" />
                <img src={HipaaImage} alt="hipaa" />
                <img src={IsoImage} alt="iso" />
              </div>
              <div className="ngs-privacy">
                By clicking "Save" I agree to the
                <a className="signup-page-link-color" href="https://www.leal.health/terms"> Terms </a>
                and
                <a className="signup-page-link-color" href="https://www.leal.health/privacy-policy"> Privacy Policy </a>
                .
                <br />
                This site is protected by reCAPTCHA and the
                <a className="signup-page-link-color" href="https://policies.google.com/privacy"> Google Privacy Policy </a>
                and
                <a className="signup-page-link-color" href="https://policies.google.com/terms"> Term of Service </a>
                apply.
              </div>
            </div>
          </div>
          <br />

        </Overlay>
      </>
    )
  );
};

export default EmailSignupForm;
